import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  createRouterMiddleware,
  initialRouterState,
} from 'connected-next-router';
import Router from 'next/router';

// Using next-redux-wrapper's initStore
const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    if (typeof window !== 'undefined' && state?.router) {
      // preserve router value on client side navigation
      nextState.router = state.router;
    }
    return nextState;
  } else {
    return rootReducer(state, action);
  }
};

export const initStore = context => {
  const routerMiddleware = createRouterMiddleware();
  const { asPath } = context.ctx || Router.router || {};
  let initialState;

  if (asPath) {
    initialState = {
      router: initialRouterState(asPath),
    };
  }

  const middleware = [thunk];

  const middlewareEnhancer = applyMiddleware(...middleware, routerMiddleware);

  const enhancers = [middlewareEnhancer];

  const composeEnhancers = composeWithDevTools(...enhancers);

  return createStore(
    reducer,
    initialState,
    process.env.NODE_ENV !== 'production'
      ? composeEnhancers
      : middlewareEnhancer
  );
};

export const wrapper = createWrapper(initStore);
