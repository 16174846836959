import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    fontFamily: 'Open Sans, sans-serif ',
    padding: '0.5em 1em',
    '& *': {
      fontFamily: 'Open Sans, sans-serif ',
    },
    '& > *': {
      margin: '0.5em 0',
    },
  },
  bgSecondary: {
    backgroundColor: '#F8F8FF',
  },
  head: {
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0.5rem',
  },
  heading: {
    fontSize: theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
  },
}));
