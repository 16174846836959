import * as t from '../types';

const getUniqueRecords = (state, resData) => {
  let stateDataIds = state.projects.map(dataState => dataState.pId);
  let data = resData.data.filter(
    dataFilter => stateDataIds.indexOf(dataFilter.pId) == -1
  );
  return {
    ...state,
    projects: state.projects.concat(data),
    total_pages: resData.total_pages,
    nearby: resData?.nearby ?? [],
    total_item_count: resData.total_item_count,
    bread_crum: resData.bread_crum,
    pageMeta: resData.pageMeta,
  };
};

export const searchReducer = (
  state = {
    loading: null,
    error: null,
    projects: [],
    nearby: [],
    total_pages: 0,
    total_item_count: 1000,
    bread_crum: [],
    userWishlists: [],
    pageMeta: {
      title: '',
      description: '',
    },
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'searchReducer') {
    switch (action.type) {
      case t.SEARCH_PAGE_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.UPDATE_SEARCH_DATA:
        return {
          ...state,
          projects: action.payload.projects,
          nearby: action.payload.nearby,
          total_pages: action.payload.total_pages,
          total_item_count: action.payload.total_item_count,
          bread_crum: action.payload.bread_crum,
          pageMeta: action.payload.pageMeta,
        };
      case t.GET_SEARCH_DATA:
        return {
          ...state,
          projects: action.payload.data,
          nearby: action.payload.nearby,
          total_pages: action.payload.total_pages,
          total_item_count: action.payload.total_item_count,
          bread_crum: action.payload.bread_crum,
          pageMeta: action.payload.pageMeta,
        };
      case t.MORE_SEARCH_DATA:
        return getUniqueRecords(state, action.payload);
      case t.GET_USER_FAVOURITES_DATA:
        return {
          ...state,
          userWishlists: action.payload.data,
        };
    }
  }
  return { ...state };
};

export const advancedFiltersReducer = (
  state = {
    loading: false,
    error: null,
    configurations: [],
    price: {},
    propertyType: [],
    Types: [],
    Possession: [],
    carpetArea: {},
    builderRating: [],
    livablityRating: [],
    investmentRating: [],
    recommended: [],
    aminities: [],
    facilities: [],
    approvedbyBank: [],
    saletype: [],
    moduleType: [],
    possession: [],
    unitType: [],
    possessionYears: [],
    iWantTo: [],
    furnishingStatus: [],
    leaseType: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'advancedFiltersReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_ADVANCED_FILTERS:
        return {
          ...state,
          configurations: action.payload.configuration,
          price: action.payload.price,
          propertyType: action.payload.propertyType,
          Types: action.payload.types,
          possessionYears: action.payload.possessionYears,
          saletype: action.payload.saletype,
          Possession: action.payload.Possession,
          carpetArea: action.payload.carpetArea,
          builderRating: action.payload.builderRating,
          livablityRating: action.payload.livablityRating,
          investmentRating: action.payload.investmentRating,
          recommended: action.payload.recommended,
          aminities: action.payload.aminities,
          facilities: action.payload.facilities,
          approvedbyBank: action.payload.approvedbyBank,
          moduleType: action.payload.iWantTo,
          possession: action.payload.possession,
          unitType: action.payload.types,
          iWantTo: action.payload.iWantTo,
          furnishingStatus: action.payload.furnishingStatus,
          leaseType: action.payload.lease_type,
        };
    }
  }
  return { ...state };
};

export const discountedDealReducer = (
  state = {
    loading: false,
    error: null,
    dealoftheweek: {},
    discountDeals: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'discountedDealReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_DISCOUNTED_DEALS:
        return {
          ...state,
          dealoftheweek: action.payload.data.dealoftheweek,
          discountDeals: action.payload.data.discountDeals,
        };
    }
  }
  return { ...state };
};

export const breadcrumReducer = (
  state = {
    loading: false,
    error: null,
    breadcrumbs_data: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'breadcrumReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_BREADCRUM_DATA:
        return {
          ...state,
          breadcrumbs_data: action.payload.data,
        };
    }
  }
  return { ...state };
};
