import { useEffect, useState } from 'react';
import styles from '../../../styles/Footer.module.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getFooterData } from '@/redux/actions/main';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import useMaterialMediaQuery from '@/components/packages/use-material-media-query';
import { KNOWLEDGE_CENTER } from '@/config/index';
import style from './styles';
import FooterPopulerLinks from './footerPopulerLinks';
import FooterPropertyIndiaLinks from './footerPropertyIndiaLinks';
import Link from 'next/link';

const FooterSection = (props = {}) => {
  const { module_type, city } = props;
  const cityId = city?.id;
  const [moduletype] = useState(module_type ? module_type : 'buy');

  const { global_selected_city } = props.headersApiData;
  //@TO-DO : uncommented deu to incomming chenge
  // const [, setCityName] = useState(
  //   city?.name ? city.name.toLowerCase() : 'mumbai'
  // );
  const [small] = useMaterialMediaQuery();

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      props.getFooterData({
        city_id: cityId ? cityId : global_selected_city?.id || 1,
        module_type: moduletype,
      });
    }
    // }
    return () => {
      mounted = true;
    };
  }, [global_selected_city, city?.name, cityId]);

  return (
    <Box sx={[style.mainSection, style.root]}>
      <div
        sx={style.footerBottom}
        className={`${style.footer} footer-section container`}>
        <FooterPopulerLinks />
        <FooterPropertyIndiaLinks />
        <Grid
          container
          wrap="nowrap"
          sx={style.columnReverse}
          justifyContent="space-between">
          <Grid item sx={style.contentWidth} xs={12} sm={12} md={6} lg={6}>
            <div
              style={{
                fontSize: '13px',
                textAlign: small ? 'left' : 'justify',
                padding: !small && '20px 20px 0 0',
              }}>
              <span>We at </span>
              <span className={`${styles.spblack}`}>Home</span>
              <span className={`${styles.spblack}`}>Bazaar</span>
              <span className={`${styles.spblack}`}>.com</span> assist home
              buyers in negotiating with developers,&nbsp;act on behalf of our
              customers, and ensure that you receive the best deal in the
              market. We are also recognized as the &quot;Leading Property
              Advisors in India&quot; for several consecutive years.
              {/* <span className={`${styles.spanblue}`}>Home</span>
              <span className={`${styles.spanorange}`}>Bazaar</span>
              <span className={`${styles.spanblue}`}>.com</span> ensures to give
              you the lowest rate in the market and also awarded as the
              &quot;Leading Property Advisor&quot; for 2017-18. */}
              <div className="container-fluid">
                <div
                  style={{
                    listStyle: 'none',
                    paddingLeft: 0,
                  }}>
                  <Grid container sx={{ paddingTop: '20px' }}>
                    <Grid item display="flex">
                      <Link
                        href="https://www.facebook.com/HomeBazaarcom/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="facebook">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.facebook
                          )}></div>
                      </Link>
                      <Link
                        href="https://www.instagram.com/HomeBazaarcom/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="instagram">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.instagram
                          )}></div>
                      </Link>
                      <Link
                        href="https://www.linkedin.com/company/HomeBazaarcom"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="linkdin">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.linkdin
                          )}></div>
                      </Link>
                      <Link
                        href="https://twitter.com/HomeBazaarcom"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="twitter">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.twitter
                          )}></div>
                      </Link>
                      <Link
                        href="https://www.youtube.com/HomeBazaarcom"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="youTube">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.youTube
                          )}></div>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
          <Box sx={style.tabFooter}>
            <Grid item xs={8} sm={8} md={8}>
              <Box sx={style.footerSubTitle}>
                <h4>Commercial Projects in India</h4>
              </Box>
              <Box sx={style.footerSubTitle}>
                <List sx={style.footerLinks}>
                  <ListItem>
                    <a href="/commercial-properties-in-mumbai" target="_blank">
                      Commercial Property in Mumbai
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/commercial-properties-in-pune" target="_blank">
                      Commercial Property in Pune
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/commercial-properties-in-chennai" target="_blank">
                      Commercial Property in Chennai
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="/commercial-properties-in-bangalore"
                      target="_blank">
                      Commercial Property in Bangalore
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/commercial-properties-in-noida" target="_blank">
                      Commercial Property in Noida
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="/commercial-properties-in-hyderabad"
                      target="_blank">
                      Commercial property in Hyderabad
                    </a>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Box sx={style.footerSubTitle}>
                <h4>Real Estate in India</h4>
              </Box>
              <Box sx={style.footerSubTitle}>
                <List sx={style.footerLinks}>
                  <ListItem>
                    <a href="/mumbai" target="_blank">
                      Mumbai Real Estate
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/pune" target="_blank">
                      Pune Real Estate
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/bangalore" target="_blank">
                      Bangalore Real Estate
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/chennai" target="_blank">
                      Chennai Real Estate
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/noida" target="_blank">
                      Noida Real Estate
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/hyderabad" target="_blank">
                      Hyderabad Real Estate
                    </a>
                  </ListItem>
                  {/* <ListItem>
                    <a href="/lonavala" target="_blank">
                      Lonavala Real Estate
                    </a>
                  </ListItem> */}
                </List>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Box sx={style.footerSubTitle}>
                <h4>New Projects in India</h4>
              </Box>
              <Box sx={style.footerSubTitle}>
                <List sx={style.footerLinks}>
                  <ListItem>
                    <a
                      href="/under-construction-projects-in-mumbai"
                      target="_blank">
                      New Projects in Mumbai
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="/under-construction-projects-in-pune"
                      target="_blank">
                      New Projects in Pune
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="/under-construction-projects-in-bangalore"
                      target="_blank">
                      New Projects in Bangalore
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="/under-construction-projects-in-chennai"
                      target="_blank">
                      New Projects in Chennai
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="/under-construction-projects-in-noida"
                      target="_blank">
                      New Projects in Noida
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="/under-construction-projects-in-hyderabad"
                      target="_blank">
                      New Projects in Hyderabad
                    </a>
                  </ListItem>
                  {/* <ListItem>
                    <a
                      href="/under-construction-projects-in-lonavala"
                      target="_blank">
                      New Projects in Lonavla
                    </a>
                  </ListItem> */}
                </List>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Box sx={style.footerSubTitle}>
                <h4>Top Cities In India</h4>
              </Box>
              <Box sx={style.footerSubTitle}>
                <List sx={style.footerLinks}>
                  <ListItem>
                    <a href="/properties-in-mumbai" target="_blank">
                      Property in Mumbai
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/properties-in-pune" target="_blank">
                      Property in Pune
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/properties-in-bangalore" target="_blank">
                      Property in Bangalore
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/properties-in-chennai" target="_blank">
                      Property in Chennai
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/properties-in-noida" target="_blank">
                      Property in Noida
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href="/properties-in-hyderabad" target="_blank">
                      Property in Hyderabad
                    </a>
                  </ListItem>
                  {/* <ListItem>
                    <a href="/properties-in-lonavala" target="_blank">
                      Property in Lonavla
                    </a>
                  </ListItem> */}
                </List>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </div>

      <div className={styles.footerData}>
        <Grid
          container
          justifyContent="space-between"
          className={ClassNames(['container'])}>
          <Grid item xs={3} sm={3} md={2} lg={3}>
            <Box sx={style.copyrightWrapper}>
              Copyright © {new Date().getFullYear()}
            </Box>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
            item
            xs={9}
            sm={9}
            md={10}
            lg={9}>
            <ul className={styles.additional_links}>
              <li>
                {/* <a href="/about-us"> */}
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/about-us">About Us</a>
                {/* </a> */}
              </li>
              {/* <li>
                <a href="#"><a>Awards</a></a>
              </li> */}
              <li>
                <a href="https://careers.HomeBazaar.com/">
                  {/* <a href="https://careers.HomeBazaar.com/" target="_blank"> */}
                  Career
                </a>
              </li>
              <li>
                {/* <a href="/our-culture"> */}
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/our-culture">Our Culture</a>
                {/* </a> */}
              </li>
              <li>
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </li>
              {/* <li>
                <a href="#"><a>Sitemap</a></a>
              </li> */}
              <li>
                <a href="/news/" target="_blank">
                  {/* <a href="/news" target="_blank"> */}
                  Real Estate News
                </a>
              </li>
              <li>
                <a href={KNOWLEDGE_CENTER}>Knowledge Center</a>
              </li>
              {/* <li>
                <a href="#"><a>Get Franchisee</a></a>
              </li> */}
              <li>
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/sitemap"> Sitemap</a>
              </li>
              <li>
                <a href="/contactus" target="_blank">
                  Contact Us
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

const mapStateToProps = state => ({
  apiData: state.footerReducer,
  headersApiData: state.headerReducer,
});

const mapDispatchToProps = {
  getFooterData,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterSection);
