const HorizontalScrollButton = props => {
  const { style, class_name, arrow_icon, on_click, disableNext, disablePrev } =
    props;
  return (
    <>
      {disableNext || disablePrev ? (
        <button
          disabled={disableNext || disablePrev}
          className={class_name}
          style={{
            ...style,
            backgroundColor: '#DEDEDE',
            cursor: 'not-allowed',
          }}>
          {arrow_icon}
        </button>
      ) : (
        <button onClick={on_click} className={class_name} style={style}>
          {arrow_icon}
        </button>
      )}
    </>
  );
};

export default HorizontalScrollButton;
