import { useEffect, useState } from 'react';
import { storeItem } from '../../../config/LocalStorage';

const useAccessToken = () => {
  let tokenInLocalStorage = '';
  if (typeof window !== 'undefined') {
    tokenInLocalStorage = localStorage.getItem('access_token');
  }
  let [access_token, setAccessTokenState] = useState('');

  useEffect(() => {
    setAccessTokenState(tokenInLocalStorage);
  }, [tokenInLocalStorage]);

  let setAccessToken = token => {
    storeItem('access_token', token);
    setAccessTokenState(token);
  };

  return [access_token, setAccessToken];
};

export default useAccessToken;
