import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClassNames from 'classnames';
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CommonHeaderText from '../../../packages/common-header-text';
import { connect } from 'react-redux';
import {
  loginSubmit,
  getShortlistedproperties,
} from '../../../../redux/actions/main';

import { storeObject } from '../../../../config/LocalStorage';
import { useForm } from '../useForm';
import HBImage from '../../../packages/hbimage';
import { API_ROOT, MYACCOUNT_HOMEBAZAAR } from '../../../../config';
import _ from 'lodash';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import { LOGIN_API, FORGET_PASSWORD } from '../../../../config/EndpointHelper';
import { headers } from '../../../../config/HeaderHelper';
import DataHelper from '../../../../config/DataHelper';
import useMaterialMediaQuery from '../../../packages/use-material-media-query';
import useWindow from '@/hooks/useWindow';
import useAccessToken from '@/components/packages/use-access-token';
import useLoginForm from '@/components/packages/use-login-form';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#fff',
    fontFamily: 'Open Sans, sans-serif',
    borderRadius: '10px',
    marginLeft: '15px',
    marginRight: '15px',
  },
  textInput: {
    margin: '0 0 16px 0',
    display: 'block',
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      height: '42px',
      color: '#171717',
    },
    '& .MuiOutlinedInput-input': {
      padding: '13px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    '& .MuiInputLabel-formControl': {
      top: '-5px',
      [theme.breakpoints.down('sm')]: {
        top: '-5px',
        fontSize: '14px',
      },
    },
  },

  formSubmit: {
    width: '100%',
    fontSize: '16px',
    backgroundColor: '#0056B2',
    color: '#fff',
    textTransform: 'capitalize',
    // transition: 'all 0.3s ease-in-out',
    margin: '32px 0 20px 0',
    borderRadius: '4px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0056B2',
      color: '#FFFFFF',
    },
  },
  loginFormpd: {
    padding: '32px 0 0 0',
  },
  specialText: {
    cursor: 'pointer',
    color: '#001AC0',
  },

  specialText1: {
    cursor: 'pointer',
    color: '#0056B2',
  },
  loadingButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -4,
    marginLeft: -12,
  },
  lineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: '1rem 0',
  },
  horizontalLine: {
    height: '1px',
    width: '100%',
    backgroundColor: '#D9D9D9',
    flexGrow: 1,
    flexShrink: 1,
  },
  lineText: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '700',
    padding: '0 1rem',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontFamily: 'sans-serif',
    marginLeft: '14px',
  },
}));

export const SocialButton = (props = {}) => {
  const {
    onClick,
    className,
    image,
    img_width,
    img_height,
    text,
    variant,
    style,
  } = props;

  return (
    <Button
      onClick={onClick}
      className={ClassNames([className])}
      variant={variant}
      style={style}>
      <span>{text}</span>
      {image && (
        <span
          style={{
            padding: '5px 5px 0 5px',
            lineHeight: '24px',
          }}>
          <HBImage
            src={image}
            width={img_width}
            height={img_height}
            alt={text}
          />
        </span>
      )}
    </Button>
  );
};

const REGEX_MOBILE = /^\d{10}$/;
const REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const isMobileNumber = s => REGEX_MOBILE.test(s);
const isEmail = s => REGEX_EMAIL.test(s);

const LoginForm = props => {
  const { root_style, root_class, noRedirect, onSubmit, showForgetPassword } =
    props;
  const classes = useStyles();
  const [small] = useMaterialMediaQuery();

  const {
    user,
    access_token: accessToken,
    loading,
    error,
  } = props.loginApiData;

  const [password_step, setPasswordStep] = useState(false);
  const [otp_error, setOtpError] = useState('');
  const [otp_loading, setOtpLoading] = useState(false);
  const [isUniqueEmail, setIsUniqueEmail] = useState(1);

  // Visibility toggles for Password and OTP and ForgetPassword
  const [show_password, setShowPassword] = useState(false);
  const [show_otp, setShowOtp] = useState(false);
  let newProjectData = [];
  let rentProjectData = [];
  let resaleProjectData = [];
  let typeArray = [];
  let idArray = [];

  const [, setAccessToken] = useAccessToken();

  const [, , closeLoginModal] = useLoginForm();

  const getshortlistedApicall = () => {
    newProjectData = JSON.parse(localStorage.getItem('buy'));
    rentProjectData = JSON.parse(localStorage.getItem('rent'));
    resaleProjectData = JSON.parse(localStorage.getItem('resale'));

    if (
      (newProjectData && newProjectData?.length > 0) ||
      (rentProjectData && rentProjectData?.length > 0) ||
      (resaleProjectData && resaleProjectData?.length > 0)
    ) {
      const wishlist = [
        {
          panelID: 'panel1',
          label: 'New Project',
          project: newProjectData ? newProjectData : [],
        },
        {
          panelID: 'panel2',
          label: 'Resale',
          project: resaleProjectData ? resaleProjectData : [],
        },
        {
          panelID: 'panel3',
          label: 'Rent',
          project: rentProjectData ? rentProjectData : [],
        },
      ];
      wishlist.forEach(wishlist_data => {
        wishlist_data.project.forEach(data => {
          const { propertyId, moduleType } = data;
          idArray.push(propertyId);
          typeArray.push(moduleType);
        });
      });
      localStorage.removeItem('buy');
      const queryParams = {
        user_id: user.id,
        action: 'add',
        property_id: idArray,
        module_type: typeArray,
      };
      props.getShortlistedproperties(queryParams);
      localStorage.removeItem('buy');
      window.dispatchEvent(new Event('storage'));
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ('email' in fieldValues)
      temp.email = fieldValues.email
        ? isEmail(fieldValues.email) || isMobileNumber(fieldValues.email)
          ? ''
          : 'Email / Mobile is not valid.'
        : 'Please enter email id / mobile number.';

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every(x => x == '');
  };

  const initialValues = {
    email: '',
    password: '',
    otp: '',
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  const sendOtp = _.debounce(async () => {
    try {
      setOtpError('');
      setOtpLoading(true);
      let payload = {
        email: values.email,
      };
      const response = await axios.post(
        `${API_ROOT}${LOGIN_API}`,
        payload,
        headers
      );

      if (response.status !== 200) throw new Error('Something went wrong.');
      // OTP is sent to mobile number stored in email field
      setPasswordStep(true);
    } catch (login_error) {
      setOtpError('Something went wrong.');
    } finally {
      setOtpLoading(false);
    }
  }, 500);

  const requestLogin = _.debounce(() => {
    let payload = {
      email: values.email,
    };
    if (values.otp !== '') {
      _.merge(payload, { otp: values.otp });
    } else {
      _.merge(payload, { password: values.password });
    }
    props.loginSubmit(payload);
  }, 500);

  async function loginFormSubmit(e) {
    e.preventDefault();
    let isValidEmail = await Promise.resolve(checkEmail(values.email));
    if (isValidEmail && validate()) {
      if (!password_step) {
        // First step submit (email or mobile)
        // Clearing previous OTP errors in case user enters email
        setOtpError('');
        if (isMobileNumber(values.email)) sendOtp();
        else setPasswordStep(true);
      } else {
        // Second step submit (otp or password)
        // Clearing previous OTP errors due to resend failures
        setOtpError('');
        requestLogin();
      }
    }
  }
  const hasWindow = useWindow();
  useEffect(() => {
    if (!loading) {
      // Login request completed or Component initally loaded
      // When component is loading initally, user and accessToken may not present
      // but loading is false, so check for truthy as well.

      if (user && user !== '') {
        storeObject('user', user);
      }

      if (accessToken && accessToken !== '') {
        setAccessToken(accessToken);
        closeLoginModal();
        // If props.noRedirect is true then login form wont redirect to user-login page.
        if (noRedirect) {
          // closes modal by invoking function passed from parent
          onSubmit();

          return false;
        }
        getshortlistedApicall();

        let target =
          MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') +
          `user-login?goTopage=${btoa(values.email)}&comeFrompage=${btoa(
            values.otp !== '' ? values.otp : values.password
          )}&toHome=1`;
        // To-Do redirection
        if (hasWindow) {
          window.location.href = target;
        }
      }
    }
  }, [loading]);

  const shouldDisableSubmit = () =>
    loading || (values.otp.trim() === '' && values.password.trim() === '');

  //check email if it is unique and valid
  const checkEmail = async emailOrPhone => {
    if (emailOrPhone.indexOf('@') !== -1) {
      let payload = {
        email: emailOrPhone,
        page: 'resetoptions',
      };
      try {
        const apiResponse = await axios.post(
          API_ROOT + FORGET_PASSWORD,
          DataHelper(payload),
          { headers: headers }
        );
        setIsUniqueEmail(apiResponse.data && apiResponse.data.flag == 1);
        setErrors({});
      } catch (login_error) {
        setIsUniqueEmail(0);
        setErrors({ email: 'Email not found' });
        return false;
      }
    } else {
      setIsUniqueEmail(1);
    }
    return true;
  };

  return (
    <>
      <div
        className={ClassNames([classes.root, root_class])}
        style={root_style}>
        {password_step ? (
          <Grid container style={{ padding: '52px' }}>
            <Grid item xs={12}>
              <CommonHeaderText
                text={
                  isMobileNumber(values.email)
                    ? `Enter OTP or Password`
                    : `Enter Password`
                }
                variant="h5"
                style={{
                  color: '#171717',
                  fontSize: '24px',
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: '400',
                  textAlign: 'left',
                }}
              />
              {isMobileNumber(values.email) && (
                <>
                  <CommonHeaderText
                    text={
                      <span>
                        OTP has been sent to {values.email}{' '}
                        <span
                          onClick={() => setPasswordStep(false)}
                          className={classes.specialText}>
                          Edit
                        </span>
                      </span>
                    }
                    variant="h5"
                    style={{
                      paddingTop: '12px',
                      color: '#171717',
                      fontSize: '14px',
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: '400',
                      textAlign: 'left',
                    }}
                  />
                </>
              )}

              <form className={classes.loginFormpd} onSubmit={loginFormSubmit}>
                {isMobileNumber(values.email) && (
                  <>
                    <div className={classes.textInput}>
                      <TextField
                        data-cy="otp"
                        name="otp"
                        id="otp"
                        type={show_otp ? 'text' : 'password'}
                        label="Enter OTP"
                        value={values.otp}
                        variant="outlined"
                        onChange={handleInputChange}
                        error={errors?.otp > 0 ? true : false}
                        style={{ width: '100%' }}
                        helperText={errors?.otp}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowOtp(!show_otp)}
                                onMouseDown={() => setShowOtp(!show_otp)}>
                                {show_otp ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <CommonHeaderText
                      text={
                        <span onClick={sendOtp} className={classes.specialText}>
                          Resend {otp_loading && <CircularProgress size={10} />}
                        </span>
                      }
                      variant="h5"
                      style={{
                        color: '#171717',
                        fontSize: '14px',
                        fontFamily: 'Open Sans, sans-serif',
                        fontWeight: '400',
                        textAlign: 'right',
                      }}
                    />
                    <div className={classes.lineContainer}>
                      <span className={classes.horizontalLine}></span>
                      <span className={classes.lineText}>Or</span>
                      <span className={classes.horizontalLine}></span>
                    </div>
                  </>
                )}
                <div className={classes.textInput}>
                  <TextField
                    data-cy="password"
                    name="password"
                    id="password"
                    label="Enter Password"
                    type={show_password ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    onChange={handleInputChange}
                    error={errors?.password > 0}
                    style={{ width: '100%' }}
                    helperText={errors?.password}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!show_password)}
                            onMouseDown={() => setShowPassword(!show_password)}>
                            {show_password ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <CommonHeaderText
                  text={
                    <span
                      onClick={() => {
                        if (showForgetPassword)
                          showForgetPassword(isUniqueEmail);
                      }}
                      className={classes.specialText}>
                      Forgot Password
                    </span>
                  }
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '14px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'right',
                  }}
                />

                {otp_error && <h5 className="error-text">{otp_error}</h5>}
                {error && <h5 className="error-text">{error}</h5>}

                <div className={classes.loadingButtonWrapper}>
                  <Button
                    type="submit"
                    className={classes.formSubmit}
                    variant="contained"
                    disabled={shouldDisableSubmit()}>
                    Submit
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </form>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container style={{ padding: small ? '42px' : '52px' }}>
              <Grid item xs={12}>
                <CommonHeaderText
                  text={`Login`}
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '28px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <CommonHeaderText
                  text={
                    <span>
                      Or {/* <a href="/sign-up"> */}
                      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                      <a className={classes.specialText1} href="/sign-up">
                        create an account
                      </a>
                      {/* </a> */}
                    </span>
                  }
                  variant="h5"
                  style={{
                    paddingTop: '12px',
                    color: '#171717',
                    fontSize: '20px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <form
                  className={classes.loginFormpd}
                  onSubmit={loginFormSubmit}>
                  <div
                    className={classes.textInput}
                    style={{ marginBottom: '0' }}>
                    <TextField
                      data-cy="email"
                      name="email"
                      id="email"
                      label="Enter Email/Mobile Number"
                      value={values.email}
                      variant="outlined"
                      onChange={handleInputChange}
                      error={errors?.email > 0 ? true : false}
                      style={{ width: '100%' }}
                      helperText={errors?.email}
                      placeholder="Enter Email/Mobile Number"
                    />
                  </div>

                  {otp_error && <h5 className="error-text">{otp_error}</h5>}
                  {errors?.email ===
                    'This email/mobile is not registred with us.' && (
                    <span className={classes.errorMessage}>
                      Please
                      <a
                        href="/sign-up"
                        target="_blank"
                        //prefetch={false}
                        style={{
                          textDecoration: 'underline',
                          color: '#f44336',
                          marginLeft: '3px',
                        }}>
                        create an account
                      </a>
                    </span>
                  )}

                  <div
                    className={classes.loadingButtonWrapper}
                    style={{
                      marginTop: '16px',
                    }}>
                    <Button
                      type="submit"
                      className={classes.formSubmit}
                      variant="contained"
                      // disabled={errors.email ? true : false}
                      disabled={!!errors.email}>
                      Login
                    </Button>
                    {otp_loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <CommonHeaderText
                    text={
                      <span>
                        By clicking on Login, I accept{' '}
                        <a
                          href="/"
                          target="_blank"
                          //prefetch={false}
                          className={classes.specialText1}>
                          Terms &amp; Conditions
                        </a>{' '}
                        &amp;{' '}
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          //prefetch={false}
                          className={classes.specialText1}>
                          Privacy Policy
                        </a>
                      </span>
                    }
                    variant="h5"
                    style={{
                      color: '#171717',
                      fontSize: '10px',
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: '400',
                      textAlign: 'left',
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  loginApiData: state.loginReducer,
});

const mapDispatchToProps = {
  loginSubmit,
  getShortlistedproperties,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
