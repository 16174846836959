import Image from 'next/legacy/image';

const HBImage = props => {
  const {
    class_name,
    alt,
    title,
    width,
    height,
    src,
    content,
    object_fit,
    priority,
    // placeholder,
    style,
    layout,
    fetchpriority = 'low',
  } = props;
  const imageLoader = ({ srcImage, widthImage, heightImage }) => {
    return `${srcImage}?tr=w-${widthImage},h-${heightImage}`;
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <figure>
          <picture>
            <Image
              style={style}
              loader={imageLoader}
              className={class_name}
              title={title}
              alt={alt}
              width={parseInt(width)}
              height={parseInt(height)}
              src={`${src??'https://ik.imagekit.io/osmz1lxs6d0/propertygallery/noimage.webp'}?tr=w-${width},h-${height}`}
              //  src={`${src}`}
              unoptimized={true}
              objectFit={object_fit || 'unset'}
              layout={layout || ''}
              priority={priority}
              // placeholder={placeholder}
              // blurDataURL={`${src}?tr=w-${width},h-${height},bl-20`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://ik.imagekit.io/osmz1lxs6d0/propertygallery/noimage.webp?tr=w-${width},h-${height}`;
              }}
              fetchpriority={fetchpriority}
            />
          </picture>
        </figure>
        {content}
      </div>
    </>
  );
};

export default HBImage;
