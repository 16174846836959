import React, { Fragment, useState, useEffect } from 'react';
import Header from '../../packages/common-header';
import Navbar from '../../packages/main-navbar';
import Grid from '@material-ui/core/Grid';
import HeaderButton from '../../packages/header-button';
import IconButton from '@mui/material/IconButton';
import ScrollToTopButton from '../../packages/scroll-to-top-button';
import NavbarDropdown from '../../packages/navbar-dropdown';
import ClassNames from 'classnames';
import { getCities, getGlobalSearchData } from '../../../redux/actions/main';
import { connect, useDispatch } from 'react-redux';
import menubar from '@/data/menubar.json';
import menuModuleTypes from '@/data/menuModuleTypes.json';
import sidebarRightData from '@/data/sidebarRightData.json';
import { hb_site_logo_white } from '@/data/siteData.json';
import { site_logo_title } from '@/data/siteData.json';
import moduleTypes from '@/data/moduleTypes.json';
import CitySearch from '../../packages/city-search';
import _ from 'lodash';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LoginPopover from '../Common/LoginPopover';
import CommonHeaderText from '../../packages/common-header-text';
import { highlightedText, emptyAllGlobalSelected } from '../../util';
import PopupDialogBox from '../../packages/popup-dialog-box';
import LoginAndResetPassword from '../Common/LoginAndResetPassword';
import { logoutUser } from '../../util';
import useLoginForm from '../../packages/use-login-form';
import useAccessToken from '../../packages/use-access-token';
import useUserInfo from '../../packages/use-user-info';
import { OPEN_STICKY_HEADER } from '../../../redux/types';
import useSearchFilter from '../../../Hooks/useSearchFilter';
import WishlistPopover from '../Common/Wishlist/WishlistPopover';
import useMaterialMediaQuery from '../../packages/use-material-media-query';
import styles from './stickyHeader.module.scss';

const StickyHeaderComponent = props => {
  const {
    static_page,
    city = { id: 1, name: 'mumbai' },
    dynamic_header,
    module_type,
    page_type,
    popular_part_id,
    locality_id,
    sub_location_id,
    developer_id,
    locations,
    developers,
    onCitySelect,
    onModuleSelect,
    propertyDetailsClick,
    onSelectMultipleCity,
    removeSearchFilter,
    is_desktop,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    addwishlisttrigger,
    wishlistPropertyDeletedCall,
    pageType,
  } = props;
  const dispatch = useDispatch();
  const { cities, global_search_data } = props.headerApiData;
  useEffect(() => {
    if (cities.length === 0) {
      props.getCities();
    }
  }, []);
  const [open_search_popper, setOpenSearchPopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mouseOnMenuBtn, setMouseOnMenuBtn] = React.useState(false);

  const openDrawer = Boolean(anchorEl);
  const [is_login_open, openLoginModal, closeLoginModal] = useLoginForm();
  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  const [keywords, setKeywords] = useState('');
  const [wishlistCount, setWishlistCount] = useState(0);
  const [anchorElwishlist, setAnchorElwishlist] = React.useState(false);
  // @TO-DO : null is creating error
  // const [anchorElwishlist, setAnchorElwishlist] = React.useState(null);
  const [click, setClick] = useState(false);
  const [medium, large] = useMaterialMediaQuery();
  const [triangle, setTriangle] = useState(false);
  const filter = useSearchFilter(state => {
    state.module = module_type;
    state.cityId = city?.id;
    state.cityName = city?.name;
  });
  useEffect(() => {
    filter.settings.cityId = city?.id;
    filter.settings.cityName = city?.name;
  }, [city?.id, city?.name]);

  useEffect(() => {
    props.getGlobalSearchData({
      selectedCity: city?.id,
      propertytype: module_type,
      keyword: keywords,
      whereNotPopularPart: popular_part_id,
      whereNotLocality: locality_id,
      whereNotSubLocality: sub_location_id,
      whereNotDeveloper: developer_id ?? [],
    });
  }, [keywords, city?.id, module_type]);

  const showWishlistCount = count => {
    setWishlistCount(count);
  };
  const handleClick = () => {
    setClick(!click);
    setAnchorElwishlist(true);
    setTriangle(true);
  };
  const handleClose = () => {
    setTriangle(false);
    setAnchorElwishlist(null);
  };
  const handleOpenSearchInput = () => {
    setOpenSearchPopper(true);
    dispatch({
      type: OPEN_STICKY_HEADER,
      meta: { reducer: 'headerReducer' },
      payload: true,
    });
  };

  const handleClickAwaySearchInput = () => {
    setOpenSearchPopper(false);
    dispatch({
      type: OPEN_STICKY_HEADER,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
  };

  const onSearchKeywordChanged = _.debounce(event => {
    setKeywords(event.target.value);
  }, 500);

  const onSelectSearch = (data, itemType) => {
    emptyAllGlobalSelected();
    switch (itemType) {
      case 'popular_part_id':
        filter.settings.popularParts = [data];
        break;
      case 'location_id':
        filter.settings.locations = [data];
        break;
      case 'sublocation_id':
        filter.settings.subLocations = [data];
        break;
      case 'developer_id':
        filter.settings.developers = [data];
    }
    filter.search();
  };

  const closeLoginDrawer = () => {
    if (!mouseOnMenuBtn) setAnchorEl(null);
  };
  const [small] = useMaterialMediaQuery();
// console.log(menuModuleTypes, menuModuleTypes);
  return (
    <Fragment>
      <Header className={styles.root} classes={ClassNames(['container-fluid'])}>
        <Navbar
          pageType={pageType}
          // is_open={open_search_popper}
          small={small}
          large={large}
          is_desktop={is_desktop}
          logo={
            <div>
              {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
              <a href="/">
                <img
                  src={hb_site_logo_white}
                  title={site_logo_title}
                  width="100%"
                  height="35px"
                  alt="logo_white"
                />
              </a>
            </div>
          }
          is_logged_in={(access_token ?? '') !== '' ? false : true}
          city_search={
            !static_page && (
              <CitySearch
                style={{
                  height: '36px!important',
                }}
                page_type={page_type}
                module_type={
                  module_type
                    ? {
                        id: 1,
                        name: module_type == 'resale' ? 'Buy' : module_type,
                      }
                    : moduleTypes[0]
                }
                cities={cities}
                modules={moduleTypes}
                onSelectModule={onModuleSelect}
                onSelectCity={onCitySelect}
                is_open={open_search_popper}
                handleOpenSearchInput={handleOpenSearchInput}
                handleClickAwaySearchInput={handleClickAwaySearchInput}
                onSelectSearch={onSelectSearch}
                global_selected_cities={locations} // Selected Locations
                global_selected_developers={developers} // Selected Developers
                onSearchKeywordChanged={onSearchKeywordChanged}
                selected_cities={city}
                global_search_data={global_search_data}
                global_selected_city={city}
                onSelectMultipleCity={onSelectMultipleCity}
                handleDeleteFilter={removeSearchFilter}
                propertyDetailsClick={propertyDetailsClick}
                keywords={keywords}
              />
            )
          }
          bgColor="#0056B2"
          navbar_buttons={
            <Grid
              justifyContent="center" // Add it here :)
              container>
              {menuModuleTypes && 
                menuModuleTypes.map((data, index) => {
                  const {
                    title,
                    links,
                    image,
                    classNameHeader,
                    classBuysellrentbtn,
                    hideForSmallScrn,
                  } = data;
                  // console.log(classNameHeader === 'hloan' , xlarge);
                  // if (classNameHeader === 'hloan' && xlarge) {
                    return (
                      <Grid key={index} item>
                        <NavbarDropdown
                          classNameTxt={hideForSmallScrn ? styles.menutext : ''}
                          image={image}
                          classNameHeader={classNameHeader}
                          classBuysellrentbtn={classBuysellrentbtn}
                          root_class={styles.rootNav}
                          text={title}
                          color="#000"
                          bgColor="#fff"
                          options={links}
                        />
                      </Grid>
                    );
                  // }
                })}
              {is_desktop && (
                <Grid item>
                  {(medium || large) && (
                    <div style={{ position: 'relative' }}>
                      <div className={triangle ? styles.triangle : ''}></div>{' '}
                    </div>
                  )}
                  <HeaderButton
                    data-cy={'header-profile-btn'}
                    showAlways={true}
                    className={styles.WishlistBtn}
                    text={
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '12px',
                          transform: 'translate(-50%, -50%)',
                          display: 'flex',
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <span
                          style={{
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            position: 'absolute',
                            top: '3px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          {wishlistCount}
                        </span>
                        <img
                          width="28px"
                          height="27px"
                          src={`/static/images/heart_orange.svg`}
                          alt="Favourite"
                        />
                      </div>
                    }
                    color="#000"
                    bgColor="#fff"
                    onMouseLeave={() => setMouseOnMenuBtn(false)}
                    onClick={handleClick}
                    style={{
                      boxShadow: 'none',
                      textTransform: 'capitalize',
                    }}
                  />
                </Grid>
              )}
              <Grid item>
                <HeaderButton
                  className={styles.NavLogin}
                  text={
                    access_token && access_token != '' ? (
                      <CommonHeaderText
                        text={
                          <span>
                            {highlightedText(
                              user?.name === undefined ? (
                                <AccountCircleIcon />
                              ) : (
                                user?.name.charAt(0)
                              )
                            )}
                          </span>
                        }
                        variant="h4"
                        style={{
                          fontSize: '20px',
                          fontWeight: '700',
                          color: '#000',
                          fontFamily: 'Fira Sans Condensed, sans-serif',
                        }}
                      />
                    ) : (
                      <AccountCircleIcon />
                    )
                  }
                  color="#fff"
                  onMouseOver={
                    access_token && access_token != ''
                      ? e => {
                          setAnchorEl(e.target);
                          setMouseOnMenuBtn(true);
                        }
                      : () => {} // noop
                  }
                  onMouseLeave={() => setMouseOnMenuBtn(false)}
                  onClick={
                    access_token && access_token != ''
                      ? () => {} // noop
                      : openLoginModal
                  }
                  // options={rent_menu_links}
                  style={{
                    boxShadow: 'none',
                    textTransform: 'capitalize',
                  }}
                />
              </Grid>
            </Grid>
          }
          menu_items={menubar}
          sidebar_right_icons={sidebarRightData}
          sidebar_root_class={styles.sidebarRootClass}
          sidebar_button={
            <IconButton
              data-cy={'sidepanel-open-btn'}
              aria-label="menu"
              style={{
                padding: '4px 0',
                color: '#000',
                margin: '0',
                borderRadius: 0,
              }}>
              <img
                src="/static/images/artboard-img.svg"
                title="Hamburger Icon"
                alt="Hamburger Icon"
                style={{
                  objectPosition: '-692px 2px',
                  objectFit: 'cover',
                  width: '19px',
                  height: '33px',
                }}
              />
            </IconButton>
          }
          all_filter_buttons={dynamic_header}
        />
      </Header>
      <LoginPopover
        openDrawer={openDrawer}
        anchorEl={anchorEl}
        closeLoginDrawer={closeLoginDrawer}
        access_token={access_token}
        user={user}
        //@ TO-DO conadition for window undefined "added this ==(hasWindow)"
        logoutUser={() => logoutUser()}
      />
      <WishlistPopover
        openDrawer={openDrawer}
        anchorEl={anchorElwishlist}
        closeLoginDrawer={handleClose}
        is_desktop={is_desktop}
        access_token={access_token}
        user={user}
        showWishlistCount={showWishlistCount}
        city={city}
        // count={count}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
      />

      <ScrollToTopButton
        activeColor="#2E72BB"
        inactiveColor="rgba(0, 0, 0, 0.6)"
        showBelow="250"
      />
      <PopupDialogBox
        root_class="custom-Login-modal"
        open={is_login_open ?? false}
        close={closeLoginModal}
        modal_content={<LoginAndResetPassword closeModel={closeLoginModal} />}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  headerApiData: state.headerReducer,
});

const mapDispatchToProps = {
  getCities,
  getGlobalSearchData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StickyHeaderComponent);
