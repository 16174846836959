export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const GET_MENUBAR = 'GET_MENUBAR';
export const GET_MAP = 'GET_MAP';
export const GET_LIVABILITY_PROJECTS = 'GET_LIVABILITY_PROJECTS';
export const NEXT_LIVABILITY_PROJECTS = 'NEXT_LIVABILITY_PROJECTS';
export const GET_INVESTMENT_PROJECTS = 'GET_INVESTMENT_PROJECTS';
export const NEXT_INVESTMENT_PROJECTS = 'NEXT_INVESTMENT_PROJECTS';
export const END_INVESTMENT_PROJECTS = 'END_INVESTMENT_PROJECTS';
export const GET_RECOMMENDED_PROJECTS = 'GET_RECOMMENDED_PROJECTS';
export const NEXT_RECOMMENDED_PROJECTS = 'NEXT_RECOMMENDED_PROJECTS';
export const GET_DEVELOPERS = 'GET_DEVELOPERS';
export const NEXT_DEVELOPERS = 'NEXT_DEVELOPERS';
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const GET_CITIES = 'GET_CITIES';
export const GET_GLOBAL_SEARCH_DATA = 'GET_GLOBAL_SEARCH_DATA';
export const SET_GLOBAL_SEARCH_DATA = 'SET_GLOBAL_SEARCH_DATA';
export const GET_POPULAR_PARTS_FOOTER = 'GET_POPULAR_PARTS_FOOTER';
export const GET_REAL_ESTATE_FOOTER = 'GET_REAL_ESTATE_FOOTER';
export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA';
export const MORE_SEARCH_DATA = 'MORE_SEARCH_DATA';
export const SET_GLOBAL_SELECTED_CITY = 'SET_GLOBAL_SELECTED_CITY';
export const SET_GLOBAL_SELECTED_CITIES = 'SET_GLOBAL_SELECTED_CITIES';
export const SET_POPULAR_PARTS_IDS = 'SET_POPULAR_PARTS_IDS';
export const SET_LOCATION_IDS = 'SET_LOCATION_IDS';
export const SET_SUB_LOCATION_IDS = 'SET_SUB_LOCATION_IDS';
export const SET_DEVELOPER_IDS = 'SET_DEVELOPER_IDS';
export const GET_PROPERTY_HEADER = 'GET_PROPERTY_HEADER';
export const POST_CONTACTED_SEEN_PROPERTY = 'POST_CONTACTED_SEEN_PROPERTY';
export const GET_PROPERTY_DETAILS = 'GET_PROPERTY_DETAILS';
export const GET_ALL_PROPERTY_DETAILS = 'GET_ALL_PROPERTY_DETAILS';
export const GET_MORE_PROJECTS = 'GET_MORE_PROJECTS';
export const GET_SIMILAR_PROJECTS = 'GET_SIMILAR_PROJECTS';
export const GET_ALL_FOOTER_DATA = 'GET_ALL_FOOTER_DATA';
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const GET_ADVANCED_FILTERS = 'GET_ADVANCED_FILTERS';
export const GET_DISCOUNTED_DEALS = 'GET_DISCOUNTED_DEALS';
export const GET_VIRTUAL_URL = 'GET_VIRTUAL_URL';
export const GET_ABOUT_LOCALITY = 'GET_ABOUT_LOCALITY';
export const GET_DEVELOPER_INFO = 'GET_DEVELOPER_INFO';
export const GET_PROPERTY_OVERVIEW = 'GET_PROPERTY_OVERVIEW';
export const GET_PROPERTY_AMENITY = 'GET_PROPERTY_AMENITY';
export const GET_PROPERTY_FLOORPLAN = 'GET_PROPERTY_FLOORPLAN';
export const GET_PROPERTY_CALCULATOR = 'GET_PROPERTY_CALCULATOR';
export const GET_PROPERTY_PRICE_INSIGHTS = 'GET_PROPERTY_PRICE_INSIGHTS';
export const GET_PROPERTY_BROCHURE = 'GET_PROPERTY_BROCHURE';
export const GET_PROPERTY_PROGRESS = 'GET_PROPERTY_PROGRESS';
export const GET_PROPERTY_BANK = 'GET_PROPERTY_BANK';
export const GET_PROPERTY_NEARBY = 'GET_PROPERTY_NEARBY';
export const GET_PROPERTY_DISTANCE = 'GET_PROPERTY_DISTANCE';
export const GET_PROPERTY_LOCALITY = 'GET_PROPERTY_LOCALITY';
export const GET_PROPERTY_BUILDER = 'GET_PROPERTY_BUILDER';
export const GET_PROPERTY_FAQ = 'GET_PROPERTY_FAQ';
export const GET_PROPERTY_RATING = 'GET_PROPERTY_RATING';
export const GET_PROPERTY_QA = 'GET_PROPERTY_QA';
export const GET_BREADCRUM_DATA = 'GET_BREADCRUM_DATA';
export const ENQUIRY_FORM_SUBMIT = 'ENQUIRY_FORM_SUBMIT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ADD_WISHLIST = 'ADD_WISHLIST';
export const GET_USER_FAVOURITES_DATA = 'GET_USER_FAVOURITES_DATA';
export const GET_BLOGPOST_PROJECTS = 'GET_BLOGPOST_PROJECTS';
export const NEXT_BLOGPOST_PROJECTS = 'NEXT_BLOGPOST_PROJECTS';
export const SET_SEARCH_PAGE_PROPS = 'SET_SEARCH_PAGE_PROPS';
export const REMOVE_SELECTED_LOCALITY = 'REMOVE_SELECTED_LOCALITY';
export const OPEN_STICKY_HEADER = 'OPEN_STICKY_HEADER';
// @TO-DO : Adding key for conditional rendering of header
export const OPEN_HEADER_BY_PAGENAME = 'OPEN_HEADER_BY_PAGENAME';
export const SET_CURRENT_CITY = 'SET_CURRENT_CITY';

export const SEARCH_PAGE_LOADING = 'SEARCH_PAGE_LOADING';
export const REMOVE_GLOBAL_SELECTED_CITIES = 'REMOVE_GLOBAL_SELECTED_CITIES';
export const END_LIVABILITY_PROJECTS = 'END_LIVABILITY_PROJECTS';
export const END_RECOMMENDED_PROJECTS = 'END_RECOMMENDED_PROJECTS';
export const END_NEXT_DEVELOPERS = 'END_NEXT_DEVELOPERS';
export const GET_PROPERTY_Gallery = 'GET_PROPERTY_Gallery';
export const GET_WISHLISTED_PROPERTIES = 'GET_WISHLISTED_PROPERTIES';
export const GET_SHORTLISTED_PROPERTIES = 'GET_SHORTLISTED_PROPERTIES';
export const GET_SEENANDCONTACTED_PROPERTIES =
  'GET_SEENANDCONTACTED_PROPERTIES';
export const GET_RECENTLY_POSTED_PROPERTIES = 'GET_RECENTLY_POSTED_PROPERTIES';
// TO-DO : adding this for rent home page scroll button
export const END_RECENTLY_POSTED_PROPERTIES = 'END_RECENTLY_POSTED_PROPERTIES';
export const NEXT_RECENTLY_POSTED_PROPERTIES =
  'NEXT_RECENTLY_POSTED_PROPERTIES';
export const SET_LEASE_PROPERTY_COUNT = 'SET_LEASE_PROPERTY_COUNT';
