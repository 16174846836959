import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  chipItem: {
    borderRadius: '0.3rem',
    textTransform: 'capitalize',
    '&.MuiChip-colorPrimary': {
      border: '1px solid rgba(0,0,0,0)',
      color: '#FFF !important',
    },
  },
}));
