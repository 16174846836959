import Button from '@mui/material/Button';
import ClassNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './headerButton.module.scss';

const HeaderButton = props => {
  const {
    txtClassName,
    text,
    variant,
    style,
    className,
    classNameHeader,
    classBuysellrentbtn,
    image,
    onClick,
    onMouseOver,
    onMouseLeave,
    options,
  } = props;
  return (
    <Button
      aria-label="header-button"
      data-cy={props['data-cy'] || 'header-button'}
      className={ClassNames([
        styles.root,
        className,
        classBuysellrentbtn === 'buysellbtn' ? styles.buysellbtn : '',
      ])}
      variant={variant}
      style={style}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <div className={classNameHeader === 'hloan' ? styles.hloan : ''}>
        {image && <img alt="Homeloan" src={image}></img>}
      </div>
      <div
        className={
          className === 'scheduleTourButton cardNotClicked'
            ? styles.txtName
            : txtClassName
        }>
        {text}
      </div>
      {options ? <ExpandMoreIcon /> : <></>}
    </Button>
  );
};

export default HeaderButton;
