import * as t from '../types';

export const virtualUrlReducer = (
  state = {
    loading: false,
    error: null,
    virtualurl: {},
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'virtualUrlReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_VIRTUAL_URL:
        return {
          ...state,
          virtualurl: action.payload,
        };
    }
  }
  return { ...state };
};

export const aboutLocalityReducer = (
  state = {
    loading: false,
    error: null,
    aboutLocality: [],
    exploreBestProperty: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'aboutLocalityReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_ABOUT_LOCALITY:
        return {
          ...state,
          aboutLocality: action.payload.aboutLocality,
          exploreBestProperty: action.payload.exploreBestProperty,
        };
    }
  }
  return { ...state };
};

export const enquiryFormReducer = (
  state = {
    loading: false,
    error: null,
    enquirydata: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'enquiryFormReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.ENQUIRY_FORM_SUBMIT:
        return {
          ...state,
          enquirydata: action.payload,
        };
    }
  }
  return { ...state };
};
