import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  suggessionItem: {
    padding: '0.4rem 1rem',
  },
  suggessionItemText: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
  },
  message: {
    padding: '1rem',
  },
}));
