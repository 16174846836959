import { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import CommonHeaderText from '../../../packages/common-header-text';
import { getShortlistedproperties } from '../../../../redux/actions/main';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import _ from 'lodash';
import {
  getPropertyDetailsURL,
  getPropertyDetailsURLForRentResale,
} from '../../../util';
import useWindow from '@/hooks/useWindow';
import classes from './wishListPopover.module.scss';

// const useStyles = makeStyles(() => ({
//   root: {
//     backgroundColor: '#FFF',
//     borderRadius: '10px',
//     borderTopLeftRadius: '10px',
//     borderTopRightRadius: '10px',
//     padding: '0px',
//     '& .MuiTabs-indicator': {
//       display: 'none',
//     },
//     '& .MuiTab-wrapper': {
//       textTransform: 'capitalize',
//     },
//     '& .MuiButtonBase-root.Mui-selected': {
//       backgroundColor: '#0056B2',
//       color: '#fffff',
//       boxShadow: 'none',
//       border: 0,
//     },
//     '& .MuiButtonBase-root.Mui-selected:first-child': {
//       borderTopLeftRadius: '10px',
//     },
//     '& .MuiButtonBase-root.Mui-selected:last-child': {
//       borderTopRightRadius: '10px',
//     },
//     '& .MuiBox': {
//       padding: '0px !important',
//     },
//   },
//   loginDrawer: {
//     display: 'block',
//     width: '285px',
//     height: 'auto',
//     textAlign: 'center',
//     textTransform: 'capitalize',
//     '& svg': {
//       margin: 6,
//       cursor: 'pointer',
//     },
//   },
//   row: {
//     display: 'flex',
//     borderBottom: '1px solid #0056B20D',
//     cursor: 'pointer',
//     alignItems: 'center',
//     '& img': {
//       margin: '16px 30px 16px 20px',
//     },
//   },

//   wishlistPopover: {
//     '& .MuiPaper-root': {
//       top: '47px !Important',
//       border: '1px solid #D6D6E8 !Important',
//       borderRadius: '10px',
//       width: '388px',

//       '& .MuiTab-root': {
//         width: '30% !important',
//         padding: '6px 6px',
//         backgroundColor: '#F3F3FF',
//         marginLeft: '3px',
//         borderRadius: '5px !important',
//         marginRight: '3px',
//         fontSize: '14px',
//         fontWeight: 'bold',
//         fontFamily: 'Open Sans !important',
//         textTransform: 'capitalize',
//         border: '1px solid #E2E2F0',
//         color: '#464646',
//         cursor: 'pointer',
//       },

//       '& .MuiTab-textColorInherit': {
//         opacity: '9 !Important',
//       },

//       '& .MuiTab-textColorInherit.Mui-selected': {
//         backgroundColor: '#2E72BB !important',
//         color: '#FFFFFF !important',
//       },

//       '& p': {
//         padding: '0px',
//       },
//     },
//   },
//   projectDetails: {
//     borderRight: '1px solid #D6D6E8',
//     padding: '5px 8px 5px 15px ',

//     '& .MuiTypography-root a': {
//       fontSize: '14px',
//       fontWeight: '700',
//       fontFamily: 'Open Sans',
//       color: '#2C2C2C',
//     },
//     '& .MuiTypography-root': {
//       fontSize: '13px',
//       fontWeight: '400',
//     },
//   },

//   listMainData: {},
//   helloTime: {
//     '& ul:nth-child(odd)': { backgroundColor: '#F3F3FF' },
//     '& ul:nth-child(even)': { backgroundColor: '#ededf2' },
//   },
//   listData: {
//     paddingTop: '0px !important',
//     paddingBottom: '0px !important',

//     '& li': {
//       borderBottom: '1px solid #E2E2F0',
//       paddingTop: '0px !important',
//       paddingBottom: '0px !important',
//       height: '79px',

//       '&:hover': {
//         backgroundColor: '#E2E2F0',
//       },
//       '&:active': {
//         backgroundColor: '#E2E2F0',
//       },

//       '& .MuiListItemAvatar-root': {
//         minWidth: '40px',
//       },
//     },

//     '& ul:nth-child(odd)': { backgroundColor: 'red' },
//     '& ul:nth-child(even)': { backgroundColor: 'Green' },
//   },

//   notSeenProperty: {
//     textAlign: 'center',
//     padding: '10px',
//   },
//   deletedImg: {
//     textAlign: 'center',
//     paddingLeft: '11px',
//     '& .MuiListItemAvatar-root': {
//       minWidth: '39px !Important',
//     },
//   },
//   tabRoot: {
//     '&.MuiTab-root': {
//       minWidth: '50% !important',
//     },
//   },
// }));

const WishlistPopover = (props = {}) => {
  const {
    anchorEl,
    closeLoginDrawer,
    access_token,
    user,
    showWishlistCount,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    addwishlisttrigger,
    wishlistPropertyDeletedCall,
  } = props;
  const [tab_value, setTabValue] = useState(0);
  const [cachedProject, setCachedProject] = useState();
  const [main_loading, setMainLoading] = useState(false);
  let newProjectData = [];
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // const classes = useStyles();
  const { wishlist, loading } = props.getShortlistedpropertiesApiData;

  const setShowWishlistCount = () => {
    let showWishlist =
      props.getShortlistedpropertiesApiData.wishlist?.wishlistCount;

    showWishlistCount(showWishlist);
  };

  if (access_token && access_token !== '') {
    setShowWishlistCount();
  }
  useEffect(() => {
    if (loading == false) {
      setMainLoading(false);
      return;
    }
  }, [loading]);

  useEffect(() => {
    if (user?.id) {
      const queryParams = {
        user_id: user.id,
        action: 'select',
      };
      props.getShortlistedproperties(queryParams);
      setShowWishlistCount();
    } else {
      getCachedApiData();
    }
  }, [user, addwishlisttrigger]);

  function areaProps(index) {
    return {
      id: `simple-tab-${index}`,
      ['data-cy']: 'tab-btn-' + index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const ShortlistedProperties = _.debounce(
    (get, user_id, action, projectId) => {
      const queryParams = {
        user_id: user_id,
        action: action,
        id: projectId,
      };
      props.getShortlistedproperties(queryParams);
      wishlistPropertyDeletedCall();
    }
  );

  const getCachedApiData = () => {
    const getItem = key => JSON.parse(localStorage.getItem(key));

    const buyData = getItem('buy') || [];
    const rentData = getItem('rent') || [];
    const resaleData = getItem('resale') || [];

    const buyCount = buyData.length;
    const rentCount = rentData.length;
    const resaleCount = resaleData.length;

    showWishlistCount(buyCount + rentCount + resaleCount);

    const wishlist_list = [
      { panelID: 'panel1', label: 'New Project', project: buyData },
      { panelID: 'panel2', label: 'Resale', project: resaleData },
      { panelID: 'panel3', label: 'Rent', project: rentData },
    ];

    if (buyCount + rentCount + resaleCount > 0) {
      setCachedProject(wishlist_list);
    } else {
      setCachedProject([]);
    }
  };

  const hasWindow = useWindow();
  useEffect(() => {
    const handleclick = () => {
      getCachedApiData();
    };
    if (hasWindow) {
      window.addEventListener('storage', handleclick);
      return () => {
        window.removeEventListener('storage', handleclick);
      };
    }
  }, []);

  const deleteApiCall = (module_type, propertyId) => {
    newProjectData = JSON.parse(localStorage.getItem(module_type.toString()));
    var newArray = newProjectData.filter(
      item => item.id.toString() !== propertyId.toString()
    );
    localStorage.setItem(module_type.toString(), JSON.stringify(newArray));
    if (hasWindow) {
      window.dispatchEvent(new Event('storage'));
    }
    setDeleteCachedPropertyTrigger(!deletecachedpropertytrigger);
  };

  const getPropertyURL = (
    moduleType,
    projectName,
    localityName,
    cityName,
    propertyId,
    configurationCount,
    maxCarpetArea,
    isResale,
    // TO-DO Added ~sqft~ for rent property
    sqft
  ) => {
    const isRent = moduleType == 'rent';
    let isResaleProp = isResale;
    let module_type = moduleType;

    if (moduleType == 'resale') {
      isResaleProp = true;
    }
    if (isResaleProp) {
      module_type = 'buy';
    }

    let propertyURL = '';
    let isWishlistRentReasale = true;

    if (isResaleProp || isRent) {
      propertyURL = getPropertyDetailsURLForRentResale(
        module_type,
        cityName,
        configurationCount,
        maxCarpetArea,
        projectName,
        propertyId,
        localityName,
        isResaleProp,
        isWishlistRentReasale,
        sqft,
        null
      );
    } else {
      propertyURL = getPropertyDetailsURL({
        module_type: module_type,
        city_name: cityName,
        property_name: projectName,
        property_id: propertyId,
        location: localityName,
      });
    }
    return propertyURL;
  };

  return (
    <>
      <section>
        <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={anchorEl ?? false}
          onClose={closeLoginDrawer}
          disableScrollLock={true}
          className={classes.wishlistPopover}>
          <Typography sx={{ p: 2 }}>
            {access_token && access_token != '' ? (
              main_loading ? (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    padding: '100px',
                  }}>
                  <img
                    src="/static/images/home-loader.gif"
                    width="50px"
                    height="50px"
                    alt="home-loader"
                  />
                </div>
              ) : (
                <div>
                  <Tabs
                    value={tab_value}
                    onChange={handleChange}
                    aria-label="tabs"
                    className="tabDetails">
                    {props.getShortlistedpropertiesApiData.wishlist?.data &&
                      wishlist.data.map((data, index) => {
                        const { label } = data;
                        return (
                          <Tab
                            className={classes.tabRoot}
                            key={index}
                            label={label}
                            {...areaProps(index)}
                          />
                        );
                      })}
                  </Tabs>

                  {props.getShortlistedpropertiesApiData.wishlist?.data &&
                    props.getShortlistedpropertiesApiData.wishlist.data.map(
                      (data, i) => {
                        const { project } = data;
                        return (
                          <div
                            style={{
                              height: '300px',
                              overflowY: 'scroll',
                              overflowX: 'hidden',
                            }}
                            key={i}
                            data-cy={'tab-btn-' + i}
                            role="tabpanel"
                            hidden={tab_value !== i}
                            id={`simple-tabpanel-${i}`}
                            aria-labelledby={`simple-tab-${i}`}
                            aria-label="tabs">
                            {project && project.length > 0 ? (
                              project &&
                              project.map((dataProject, index) => {
                                const {
                                  projectName,
                                  buildingName,
                                  price,
                                  configurationCount,
                                  furnishStatus,
                                  projectImage,
                                  id,
                                  moduleType,
                                  localityName,
                                  cityName,
                                  propertyId,
                                  maxCarpetArea,
                                  isResale,
                                  sqft,
                                } = dataProject;
                                let Sqft = sqft ?? maxCarpetArea;
                                return (
                                  <div
                                    key={index}
                                    className={classes.listMainData}>
                                    <Grid>
                                      <List className={classes.listData}>
                                        <ListItem>
                                          <ListItemAvatar>
                                            <Avatar
                                              sx={{ width: 80, height: 50 }}
                                              variant="square">
                                              <img
                                                className={
                                                  classes.activeMenuImg
                                                }
                                                src={projectImage[0]}
                                                alt="Image"
                                              />
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                            className={classes.projectDetails}>
                                            <a
                                              style={{ cursor: 'pointer' }}
                                              href={getPropertyURL(
                                                moduleType,
                                                buildingName || projectName,
                                                localityName,
                                                cityName,
                                                propertyId,
                                                configurationCount,
                                                maxCarpetArea,
                                                isResale,
                                                Sqft
                                              )}
                                              target="_blank"
                                              rel="noreferrer">
                                              <div
                                                key={index}
                                                style={{ cursor: 'pointer' }}>
                                                {projectName}
                                              </div>
                                            </a>
                                            <div>{price}</div>
                                            <div>
                                              {moduleType == 'rent'
                                                ? furnishStatus
                                                : configurationCount}
                                            </div>
                                          </ListItemText>
                                          <ListItemAvatar
                                            className={classes.deletedImg}
                                            onClick={() =>
                                              ShortlistedProperties(
                                                true,
                                                user?.id,
                                                'delete',
                                                id
                                              )
                                            }>
                                            {/* <DeleteOutlineIcon /> */}
                                            <img
                                              src="/static/images/newdeletedimg.png"
                                              width="25px"
                                              height="25px"
                                              alt="home-loader"
                                            />
                                          </ListItemAvatar>
                                        </ListItem>
                                      </List>
                                    </Grid>
                                  </div>
                                );
                              })
                            ) : (
                              <Grid className={classes.notSeenProperty}>
                                <img
                                  width="200px"
                                  height="200px"
                                  src="/static/images/shortlisted.png"
                                  alt="Street View"
                                />

                                <CommonHeaderText
                                  text={
                                    <span>
                                      You have not yet shortlisted any property
                                    </span>
                                  }
                                  variant="h5"
                                  style={{
                                    fontFamily: 'Open Sans !important',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    color: '#464646',
                                    paddingTop: '20px',
                                  }}
                                />
                              </Grid>
                            )}
                          </div>
                        );
                      }
                    )}
                </div>
              )
            ) : (
              <div>
                <Tabs
                  value={tab_value}
                  onChange={handleChange}
                  aria-label="tabs"
                  className="tabDetails">
                  {cachedProject &&
                    cachedProject.map((data, index) => {
                      const { label } = data;
                      return (
                        <Tab
                          className={classes.tabRoot}
                          key={index}
                          label={label}
                          {...areaProps(index)}
                        />
                      );
                    })}
                </Tabs>
                {cachedProject && cachedProject.length > 0 ? (
                  cachedProject &&
                  cachedProject.map((data, i) => {
                    const { project } = data;
                    return (
                      <div
                        style={{
                          height: '370px',
                          overflowY: 'scroll',
                          overflowX: 'hidden',
                        }}
                        key={i}
                        data-cy={'tab-btn-' + i}
                        role="tabpanel"
                        hidden={tab_value !== i}
                        id={`simple-tabpanel-${i}`}
                        aria-labelledby={`simple-tab-${i}`}
                        aria-label="tabs"
                        className={classes.helloTime}>
                        {project && project.length > 0 ? (
                          project &&
                          project.map((dataProject, index) => {
                            const {
                              projectName,
                              buildingName,
                              price,
                              configurationCount,
                              image,
                              propertyId,
                              moduleType,
                              cityName,
                              furnishingStatus,
                              localityName,
                              maxCarpetArea,
                              isResale,
                              sqft,
                            } = dataProject;
                            let Sqft = sqft ?? maxCarpetArea;

                            return (
                              <List className={classes.listData} key={index}>
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar
                                      sx={{ width: 80, height: 50 }}
                                      variant="square">
                                      <img
                                        className={classes.activeMenuImg}
                                        src={image[0] ?? ''}
                                        alt="Image"
                                        style={{
                                          width: '80px',
                                          height: '50px',
                                          objectFit: 'contain',
                                        }}
                                      />
                                    </Avatar>
                                  </ListItemAvatar>

                                  <ListItemText
                                    className={classes.projectDetails}>
                                    <a
                                      style={{ cursor: 'pointer' }}
                                      href={getPropertyURL(
                                        moduleType,
                                        buildingName || projectName,
                                        localityName,
                                        cityName,
                                        propertyId,
                                        configurationCount,
                                        maxCarpetArea,
                                        isResale,
                                        Sqft
                                      )}
                                      target="_blank"
                                      rel="noreferrer">
                                      <div
                                        key={index}
                                        style={{ cursor: 'pointer' }}>
                                        {projectName}
                                      </div>
                                    </a>
                                    <div>{price}</div>
                                    {moduleType === 'buy' ? (
                                      <div>{configurationCount}</div>
                                    ) : (
                                      <div>{furnishingStatus}</div>
                                    )}
                                    {/* <div>
                                      {moduleType == 'rent'
                                        ? furnishStatus
                                        : configurationCount}
                                    </div> */}
                                  </ListItemText>

                                  <ListItemAvatar
                                    style={{ textAlign: 'center' }}
                                    onClick={() =>
                                      deleteApiCall(moduleType, propertyId)
                                    }>
                                    <img
                                      src="/static/images/newdeleteimg.png"
                                      width="25px"
                                      height="25px"
                                      alt="newdeleteimg"
                                    />
                                  </ListItemAvatar>
                                </ListItem>
                              </List>
                            );
                          })
                        ) : (
                          <Grid className={classes.notSeenProperty}>
                            <img
                              width="200px"
                              height="200px"
                              src="/static/images/shortlisted.png"
                              alt="Street View"
                            />

                            <CommonHeaderText
                              text={
                                <span>
                                  You have not yet shortlisted any property
                                </span>
                              }
                              variant="h5"
                              style={{
                                fontFamily: 'Open Sans !important',
                                fontWeight: '500',
                                textAlign: 'center',
                                fontSize: '18px',
                                color: '#464646',
                                paddingTop: '20px',
                              }}
                            />
                          </Grid>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Grid className={classes.notSeenProperty}>
                    <img
                      width="200px"
                      height="200px"
                      src="/static/images/shortlisted.png"
                      alt="Street View"
                    />

                    <CommonHeaderText
                      text={
                        <span>You have not yet shortlisted any property</span>
                      }
                      variant="h5"
                      style={{
                        fontFamily: 'Open Sans !important',
                        fontWeight: '500',
                        textAlign: 'center',
                        fontSize: '18px',
                        color: '#464646',
                        paddingTop: '20px',
                      }}
                    />
                  </Grid>
                )}
              </div>
            )}
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/shortlisted-properties">
              <div
                style={{
                  backgroundColor: '#2E72BB',
                  width: '50%',
                  display: 'grid',
                  justifyItems: 'center',
                  alignSelf: 'center',
                  padding: '9px 0px',
                  margin: '14px auto -2px',
                  borderRadius: '5px',
                  marginBottom: '16px',
                }}>
                <span
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}>
                  View All
                </span>
              </div>
            </a>
          </Typography>
        </Popover>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  getShortlistedpropertiesApiData: state.getShortlistedpropertiesReducer,
});

const mapDispatchToProps = {
  getShortlistedproperties,
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPopover);
