import Typography from '@material-ui/core/Typography';
import ClassNames from 'classnames';

const CommonHeaderText = props => {
  const { text, variant, style, component, root_class } = props;
  return (
    <>
      <Typography
        className={ClassNames([root_class])}
        variant={variant === 'p' ? 'body1' : variant}
        component={component}
        style={style}>
        {text}
      </Typography>
    </>
  );
};

export default CommonHeaderText;
