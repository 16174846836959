export const LIVABILITY_API = 'api/livability/';
export const INVESTMENT_API = 'api/investment/';
export const RECOMMENDED_API = 'api/recommend/';
export const DEVELOPERS_API = 'api/developers/';
export const TESTIMONIALS_API = 'api/v2/testimonials';
export const CITIES_API = 'api/v2/activecity';
export const GLOBAL_SEARCH_DATA_API = 'api/v2/globalsearch';
export const FOOTER_DATA_API = 'api/footerdata';
export const FOOTER_POPULAR_DATA_API = 'api/footerdatapopular/';
export const SEARCH_FILTER_API = 'api/v2/searchfilter';
export const GET_POPULAR_LOCALITIES = 'api/v2/getlocalitylist';
export const HOMEPAGE_PROPERTY_LIST_API = 'api/v2/homepagepropertylist';
export const GET_DEVELOPERS_API = 'api/v2/locationdeveloper';
export const GET_PROPERTY_HEADER_API = 'api/v2/property-header';
export const POST_CONTACTED_SEEN_PROPERTY_API =
  'api/v2/addcontactedseenproperty';
export const GET_PROPERTY_DETAILS_API = 'api/v2/property-details';
export const GET_PROPERTY_DETAILS_NEW_API = 'api/v2/property-details-new';
export const GET_SEARCH_FOOTER_DATA_API = 'api/v2/searchfooter';
export const GET_FOOTER_DATA_API = 'api/v2/footerdata';
export const LOGIN_API = 'api/auth/v2/login';
export const GET_MENUBAR_API = 'api/v2/menubar';
export const GET_MAP_DETAILS = 'api/v2/getmapdetails';
export const GET_ALL_COUNTRIES_API = 'api/v2/getallcountries';
export const GET_ADVANCED_FILTERS_API = 'api/v2/getadvancedfilters';
export const GET_DISCOUNTED_DEALS_API = 'api/v2/getdealoftheweekdiscountdeal';
export const GET_VIRTUAL_URL = 'api/v2/virtualurl';
export const GET_ABOUT_LOCALITY = 'api/v2/aboutlocality';
export const GET_DEVELOPER_INFO_API = 'api/v2/getdeveloperinfo';
export const GET_BREADCRUMS_API = 'api/v2/breadcrums';
export const ENQUIRY_FORM_SUBMIT_API = 'api/v2/scheduletour';
export const SIGN_UP_API = 'api/auth/v2/signup';
export const SEND_OTP_API = 'api/auth/v2/sendotp';
export const LOGOUT_USER_API = 'api/auth/v2/logout';
export const ADD_WISHLIST_API = 'api/v2/addwishlist';
export const CONTACT_FORM_API = 'api/v2/contactform';
export const USER_ALL_WISHLIST = 'api/v2/allwishlist';
export const BLOG_POST_API = 'api/v2/getblogpost';
export const DISCOUNT_DEAL = 'api/v2/discountdeal';
export const VIRTUAL_URL = 'api/virtualurl';
export const DISCOUNT_DEAL_SEARCH_FILTER = 'api/v2/discountdealsearchfilter';
export const CHECK_UNIQUE = 'api/v2/checkunique';
export const GET_WISHLISTED_PROPERTIES = 'api/v2/getshortlistedproperties';
export const GET_SEEN_ANDCONTACTED_PROPERTIES =
  'api/v2/contactedseenpropertylist';
export const GET_PROPERTY_GALLERY_API = 'api/v2/property_gallery?';

export const FORGET_PASSWORD = 'api/v2/forgot-password';
export const VERIFY_OTP = 'api/v2/verifyotp';
export const RESET_FORGET_PASSWORD = 'api/v2/resetforgotpassword';
export const GET_LEASE_PROPERTY_COUNT = 'api/v2/rentalcollection';
export const PARTNER_WITH_US = 'api/v2/send_partner_request';
export const GET_BUILDER_FILTER = 'api/v2/getbuilderfilter';
export const GET_PROPERTY_COUNT = 'api/v2/getpropertycount';

export const GET_PROPERTIES_FOR_MAP = 'api/v2/getpropdetailsformap';
export const SEND_PROMOTIONAL_LEADS = 'api/v2/addpromotionalleads';
export const SEND_HOMELOAN_LEAD = 'api/v2/sendhomeloanlead';
export const SEND_REAL_ESTATE_JOBS_LEAD = 'api/v2/contact_job';
/* BACK UP*/
