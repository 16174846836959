import { HYDRATE } from 'next-redux-wrapper';
import * as t from '../types';

export const menubarReducer = (
  state = {
    loading: false,
    error: null,
    menubar: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'menubarReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_MENUBAR:
        return {
          ...state,
          menubar: action.payload,
        };
    }
  }
  return { ...state };
};

export const mapdetailReducer = (
  state = {
    loading: false,
    error: null,
    mapdetails: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'mapdetailReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_MAP:
        return {
          ...state,
          mapdetails: action.payload,
        };
    }
  }
  return { ...state };
};

export const recommendedReducer = (
  state = {
    loading: false,
    error: null,
    projects: [],
    end: false,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'recommendedReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_RECOMMENDED_PROJECTS:
        return {
          ...state,
          projects: action.payload,
        };
      case t.NEXT_RECOMMENDED_PROJECTS:
        return {
          ...state,
          projects: state.projects.concat(action.payload),
        };
      case t.END_RECOMMENDED_PROJECTS:
        return {
          ...state,
          end: true,
        };
      case HYDRATE:
        return {
          ...state,
          projects: action.payload,
        };
      default:
        return {
          ...state,
          projects: action.payload,
        };
    }
  }
  return { ...state };
};
export const recentlyPostedReducer = (
  state = {
    loading: false,
    error: null,
    projects: [],
    end: false,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'recentlyPostedReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_RECENTLY_POSTED_PROPERTIES:
        return {
          ...state,
          projects: action.payload,
        };
      case t.NEXT_RECENTLY_POSTED_PROPERTIES:
        return {
          ...state,
          projects: state.projects.concat(action.payload),
        };
      // To-DO : adding this for end key in rent home page
      case t.END_RECENTLY_POSTED_PROPERTIES:
        return {
          ...state,
          end: true,
        };
      case HYDRATE:
        return {
          ...state,
          projects: action.payload,
        };
      default:
        return {
          ...state,
          projects: action.payload,
        };
    }
  }
  return { ...state };
};

export const livabilityReducer = (
  state = {
    loading: false,
    error: null,
    projects: [],
    end: false,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'livabilityReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_LIVABILITY_PROJECTS:
        return {
          ...state,
          projects: action.payload,
        };
      case t.NEXT_LIVABILITY_PROJECTS:
        return {
          ...state,
          projects: state.projects.concat(action.payload),
        };
      case t.END_LIVABILITY_PROJECTS:
        return {
          ...state,
          end: true,
        };
      case HYDRATE:
        return {
          ...state,
          projects: action.payload,
        };
      default:
        return {
          ...state,
          projects: action.payload,
        };
    }
  }
  return { ...state };
};

export const investmentReducer = (
  state = {
    loading: false,
    error: false,
    projects: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'investmentReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_INVESTMENT_PROJECTS:
        return {
          ...state,
          projects: action.payload,
        };
      case t.NEXT_INVESTMENT_PROJECTS:
        return {
          ...state,
          projects: state.projects.concat(action.payload),
        };
      case HYDRATE:
        return {
          ...state,
          projects: action.payload,
        };
      default:
        return {
          ...state,
          projects: action.payload,
        };
    }
  }
  return { ...state };
};

export const developersReducer = (
  state = {
    loading: false,
    error: null,
    developers: [],
    count: 0,
    end: false,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'developersReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_DEVELOPERS:
        return {
          ...state,
          developers: action.payload?.developers,
          count: action.payload?.count,
        };
      case t.NEXT_DEVELOPERS:
        return {
          ...state,
          developers: state.developers.concat(action.payload?.developers),
          count: action.payload?.count,
        };
      case t.END_NEXT_DEVELOPERS:
        return {
          ...state,
          end: true,
        };
    }
  }
  return { ...state };
};

export const testimonialsReducer = (
  state = {
    loading: false,
    error: null,
    testimonials: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'testimonialsReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_TESTIMONIALS:
        return {
          ...state,
          testimonials: action.payload,
        };
    }
  }
  return { ...state };
};

export const headerReducer = (
  state = {
    loading: false,
    error: null,
    cities: [],
    global_search_data: {
      popularPart: [],
      localities: [],
      sub_localities: [],
      properties: [],
      developers: [],
    },
    global_selected_city: {},
    global_selected_cities: [],
    popular_parts_ids: null,
    location_ids: null,
    sublocation_ids: null,
    developer_ids: null,
    openStickyHeader: false,
    // @TO-DO : Adding key for conditional rendering of header
    pageName: '',
    current_City: { id: 1, name: 'mumbai' },
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'headerReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_CITIES:
        return {
          ...state,
          cities: action.payload,
        };
      case t.GET_GLOBAL_SEARCH_DATA:
        return {
          ...state,
          global_search_data: action.payload,
        };
      case t.SET_GLOBAL_SEARCH_DATA:
        return {
          ...state,
          global_search_data: action.payload,
        };
      case t.SET_GLOBAL_SELECTED_CITY:
        return { ...state, global_selected_city: action.payload };
      case t.SET_GLOBAL_SELECTED_CITIES:
        return { ...state, global_selected_cities: action.payload };
      case t.SET_POPULAR_PARTS_IDS:
        return { ...state, popular_parts_ids: action.payload ?? null };
      case t.SET_LOCATION_IDS:
        return { ...state, location_ids: action.payload };
      case t.SET_SUB_LOCATION_IDS:
        return { ...state, sublocation_ids: action.payload };
      case t.SET_DEVELOPER_IDS:
        return { ...state, developer_ids: action.payload };
      case t.REMOVE_SELECTED_LOCALITY:
        return {
          ...state,
          location_ids: state.location_ids
            ? state.location_ids.filter(item => item !== action.payload)
            : [],
        };
      case t.OPEN_STICKY_HEADER:
        return {
          ...state,
          openStickyHeader: action.payload,
        };

      // @TO-DO : Adding key for conditional rendering of header
      case t.OPEN_HEADER_BY_PAGENAME:
        return {
          ...state,
          pageName: action.payload,
        };
      // @TO-DO : Adding key for setting current city
      case t.SET_CURRENT_CITY:
        return {
          ...state,
          current_City: action.payload,
        };
    }
  }
  return { ...state };
};

export const footerReducer = (
  state = {
    isEmpty: true,
    loading: false,
    error: null,
    popular_part: [],
    real_estate: [],
    others: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'footerReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_ALL_FOOTER_DATA:
        return {
          ...state,
          isEmpty: false,
          popular_part: action.payload.popular_part,
          real_estate: action.payload.real_estate,
          others: action.payload.others,
        };
    }
  }
  return { ...state };
};

export const loginReducer = (
  state = {
    loading: false,
    error: null,
    user: {},
    access_token: '',
    token_type: '',
    expires_at: '',
    responseCode: '',
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'loginReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
          responseCode: action?.payload?.responseCode || '',
        };
      case t.LOGIN_SUBMIT:
        return {
          ...state,
          user: action.payload.user,
          access_token: action.payload.access_token,
          token_type: action.payload.token_type,
          expires_at: action.payload.expires_at,
          responseCode: action.payload.responseCode,
        };
    }
  }
  return { ...state };
};

export const countriesReducer = (
  state = {
    loading: false,
    error: null,
    countries: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'countriesReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_ALL_COUNTRIES:
        return {
          ...state,
          countries: action.payload,
        };
    }
  }
  return { ...state };
};

export const addWishlistReducer = (
  state = {
    loading: false,
    error: null,
    wishlist: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'addWishlistReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.ADD_WISHLIST:
        return {
          ...state,
          wishlist: action.payload,
        };
    }
  }
  return { ...state };
};

export const getShortlistedpropertiesReducer = (
  state = {
    loading: false,
    error: null,
    wishlist: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;

  if (reducer === 'getShortlistedpropertiesReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_SHORTLISTED_PROPERTIES:
        return {
          ...state,
          wishlist: action.payload,
        };
    }
  }
  return { ...state };
};

export const getSeenandContactedpropertiesReducer = (
  state = {
    loading: false,
    error: null,
    wishlist: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'getSeenandContactedpropertiesReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_SEENANDCONTACTED_PROPERTIES:
        return {
          ...state,
          seenandcontacted: action.payload,
        };
    }
  }
  return { ...state };
};

export const BlogPostReducer = (
  state = {
    loading: false,
    error: null,
    projects: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'BlogPostReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_BLOGPOST_PROJECTS:
        return {
          ...state,
          projects: action.payload,
        };
      case t.NEXT_BLOGPOST_PROJECTS:
        return {
          ...state,
          projects: state.projects.concat(action.payload),
        };
    }
  }
  return { ...state };
};

export const searchPagePropsReducer = (
  state = {
    loading: false,
    error: null,
    search_page_props: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'searchPagePropsReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.SET_SEARCH_PAGE_PROPS:
        return { ...state, search_page_props: action.payload };
    }
  }
  return { ...state };
};

export const LeastPropCountReducer = (
  state = {
    loading: false,
    error: null,
    lease_property_count: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'leaseTypeCountReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.SET_LEASE_PROPERTY_COUNT:
        return { ...state, lease_property_count: action.payload };
    }
  }
  return { ...state };
};
