import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    minHeight: '100%',
  },
  localityItem: {
    paddingLeft: '0.2rem',
  },
}));
