import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  bottomAction: {
    margin: '0 auto',
    textTransform: 'none',
  },
  searchSection: {
    paddingBottom: 0,
  },
  searchBox: {
    margin: '0.4em 0',
    backgroundColor: '#ffffff',
    width: '100%',
    '& > .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  suggestions: {
    minHeight: 'calc(100% - 56px)',
    padding: 0,
  },
  chipItem: {
    margin: '0 0.2rem',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '0.3rem',
    textTransform: 'capitalize',
  },
  scrollContainer: {
    padding: '0.3rem 0',
  },
  popularRoot: {
    minHeight: '100%',
  },
}));
