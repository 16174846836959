import { useEffect, useState, useRef } from 'react';
import HBImage from '../../../../../components/packages/hbimage';
import Section from '../../Section';
import { List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import useStyles from './styles';
import axios from '../../../../../redux/actions/axios-instance-config';
import { API_ROOT } from '../../../../../config';
import { GET_POPULAR_LOCALITIES } from '../../../../../config/EndpointHelper';

const StyledListItemText = withStyles({
  primary: {
    fontSize: '0.9rem',
  },
})(ListItemText);

function PopularLocalitiesSuggestions(props) {
  const { parts, onItemClick } = props;
  const classes = useStyles();
  const [popularLocalities, setPopularLocalities] = useState([]);
  const hasCreatedEventListner = useRef(false);

  useEffect(() => {
    const fetchLocalities = async popularPartsIds => {
      try {
        const response = await axios.post(
          `${API_ROOT}${GET_POPULAR_LOCALITIES}`,
          {
            popular_part_id: popularPartsIds,
          }
        );
        if (response.status === 200) {
          setPopularLocalities(response.data.data);
          hasCreatedEventListner.current = true;
        }
      } catch (err) {
        console.error(err);
        hasCreatedEventListner.current = false;
      }
    };
    if (!hasCreatedEventListner.current) {
      fetchLocalities(parts.map(i => i.id));
    }
  }, [parts]);

  return popularLocalities.length > 0 ? (
    <Section
      rootClass={classes.root}
      title="Popular Localities"
      icon={
        <HBImage
          src={'/static/images/search-filter/map-marker.svg'}
          alt="popular localities icon"
          width={24}
          height={24}
        />
      }>
      <List disablePadding={true} aria-label="popular localities">
        {popularLocalities.map(locality => (
          <ListItem
            key={`${locality.name}-${locality.id}`}
            className={classes.localityItem}
            onClick={() =>
              onItemClick &&
              typeof onItemClick === 'function' &&
              onItemClick(locality)
            }
            button
            divider>
            <StyledListItemText primary={locality.name} />
          </ListItem>
        ))}
      </List>
    </Section>
  ) : (
    <Section rootClass={classes.root}></Section>
  );
}

export default PopularLocalitiesSuggestions;
