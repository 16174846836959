export const storeArray = (item_name, item_value) => {
  const local_items = [];
  local_items.push(item_value);
  const local_item = JSON.stringify(local_items);
  localStorage.setItem(item_name, local_item);
};

export const retrieveArray = item_name => {
  const local_item = localStorage.getItem(item_name);
  const item = JSON.parse(local_item);
  return item;
};

export const emptyArray = item_name => {
  localStorage.setItem(item_name, JSON.stringify([]));
};

export const storeObject = (item_name, item_value) => {
  // console.log("item_name",item_name,item_value,city)
  const local_item = JSON.stringify(item_value);
  localStorage.setItem(item_name, local_item);
};

export const retrieveObject = item_name => {
  const local_item = localStorage.getItem(item_name);
  const item = JSON.parse(local_item);
  return item;
};

export const emptyObject = item_name => {
  localStorage.setItem(item_name, JSON.stringify({}));
};

export const storeItem = (item_name, item_value) => {
  localStorage.setItem(item_name, item_value);
};

export const emptyItem = item_name => {
  localStorage.setItem(item_name, '');
};

export const getItem = item_name => {
  return localStorage.getItem(item_name);
};
