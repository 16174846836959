const footerMainTitlekeyfeture = {
  '& h4': {
    fontSize: '18px !important',
    fontWeight: '700',
    color: '#0056B2',
    letterSpacing: '-0.32px !important',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '26px',
    marginTop: '30px',
  },
};

const footerSubTitlekeyfeature = {
  '& h4': {
    fontSize: '16px !important',
    fontWeight: '700',
    color: '#0056B2',
    letterSpacing: '-0.32px !Important',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '10px',
  },
  '& h5,li,a': {
    fontSize: '13px',
    fontWeight: '700',
    color: '#171717',
    letterSpacing: '-0.26px !important',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '0px',
  },
  '& ul': {
    paddingTop: '0px!important',
  },
};
const wrapperkey = {
  maxHeight: '300px',
  display: 'flex',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    display: 'block!important',
    background: 'transparent',
    // background: '#c3bebe',
    borderRadius: '10px',
  },
};
// key feature css  end
const footerMainTitle = {
  '& h4': {
    fontSize: '16px !important',
    fontWeight: '700',
    color: '#0056B2',
    letterSpacing: '-0.32px !important',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '26px',
    marginTop: '30px',
  },
};
const footerSubTitle = {
  '& h4': {
    fontSize: '16px !important',
    fontWeight: '700',
    color: '#0056B2',
    letterSpacing: '-0.32px !Important',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '10px',
  },
  '& h5,li,a': {
    fontSize: '13px!important',
    fontWeight: '700',
    color: '#171717',
    letterSpacing: '-0.26px !important',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '0px',
  },
  '& ul': {
    paddingTop: '0px!important',
  },
};
const footerLinks = {
  '& li ,a': {
    padding: '0px!important',
    fontWeight: '400!important',
  },
  'a:hover': {
    color: '#0056b2!important',
  },
};
const horizontalLine = {
  width: '100%',
  backgroundColor: 'rgb(224 224 224)!important',
  margin: '20px 0px 0px 0px!important',
};
const wrapper = {
  maxHeight: '300px',
  display: 'flex',
  overflowX: 'scroll',
};
const item = theme => ({
  minWidth: '250px',
  display: 'flex',
  height: 'auto',
  [theme.breakpoints.up('md')]: {
    minWidth: '240px',
  },
});
const columnReverse = theme => ({
  marginTop: '20px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse!important',
  },
});
const tabFooter = theme => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'inline-block!important',
  },
});
const contentWidth = theme => ({
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%!important',
    borderTop: '1px solid #ddd',
    marginTop: '16px!important',
  },
  [theme.breakpoints.down('sm')]: {
    // borderTop: 'none',
  },
});
const copyrightWrapper = {
  textAlign: 'left',
  fontWeight: '700',
  lineHeight: '30px',
  // opacity: '0.8',
};

const root = theme => ({
  background: '#EAEEFF',
  [theme.breakpoints.down('md')]: {
    margin: 0,
    background: '#EAEEFF',
  },
  [theme.breakpoints.up('md')]: {
    background: '#f3f3ff',
  },
  [theme.breakpoints.down('md')]: {
    background: '#f3f3ff',
  },
  '& .grid-item': {
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      padding: '0 2rem !important',
      '&:nth-child(2), &:nth-child(1)': {
        marginTop: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      '& .popular-part-item-wrapper': {
        paddingLeft: '2rem',
      },
    },
  },

  '& .footer-section': {
    [theme.breakpoints.down('md')]: {
      paddingBottom: '1.5rem',
      maxWidth: '100vw',
      overflow: 'hidden',
    },
  },
});
const footerBottom = {
  paddingTop: '20px!important',
  paddingBottom: '20px!important',
};
const footer = {
  height: 'auto',
};

export default {
  footerMainTitle,
  footerSubTitle,
  footerSubTitlekeyfeature,
  footerLinks,
  horizontalLine,
  wrapper,
  wrapperkey,
  item,
  columnReverse,
  tabFooter,
  contentWidth,
  copyrightWrapper,
  root,
  footerBottom,
  footer,
  // key feature css

  footerMainTitlekeyfeture,
};
