import Section from '../Section';
import RangeSlider from '../RangeSlider';
import { useMemo } from 'react';

const priceSuffixMap = {
  crore: 'Cr',
  lacs: 'L',
};

function PriceFilter(props) {
  const { rootClass, loading, data, defaultPriceRange, onChange } = props;

  const valueLabelMap = useMemo(() => {
    const m = new Map();
    data.minPrice?.forEach(({ label, value }) => {
      m.set(value, label);
    });
    data.maxPrice?.forEach(({ label, value }) => {
      m.set(value, label);
    });
    if (m.size > 0) {
      // This is to allow user set filter value beyond max price.
      // Value beyond max price is represented by max price + 1.
      // Label is created by adding `+` character after number in max price label.
      let { label: lastLabel, value: lastValue } =
        data.maxPrice[data.maxPrice.length - 1];
      lastLabel = lastLabel.split(' ');
      lastLabel = `${lastLabel[0]}+ ${lastLabel[1]}`;
      lastValue++;
      m.set(lastValue, lastLabel);
    }
    return m;
  }, [data]);

  const formatLabel = value => {
    let label = String(valueLabelMap.get(value) ?? '');
    if (!label) return '';
    const [amount, suffix] = label.split(/\s+/);
    let mappedSuffix = priceSuffixMap[suffix?.toLowerCase()];
    if (!mappedSuffix) {
      mappedSuffix = suffix;
    }
    return [amount, mappedSuffix].join(' ');
  };

  // This is to properly initialize 0 max price to value beyond max which indicates max price is open.
  const valueBeyondMax = useMemo(
    () => (valueLabelMap.size > 0 ? Math.max(...valueLabelMap.keys()) : 0),
    [valueLabelMap]
  );
  let [initialMinPrice, initialMaxPrice] = defaultPriceRange;
  if (initialMaxPrice === 0) initialMaxPrice = valueBeyondMax;

  const handleRangeChanged = (min, max) => {
    if (onChange && typeof onChange === 'function') {
      // Clear max limit when value beyound max price is selected
      if (max === valueBeyondMax) onChange(min, 0);
      else onChange(min, max);
    }
  };

  return (
    <Section title="Price" rootClass={rootClass}>
      {loading && <p>Loading...</p>}
      {!loading && (
        <RangeSlider
          values={[...valueLabelMap.keys()]}
          inclusiveMin={false}
          inclusiveMax={false}
          formatLabel={formatLabel}
          defaultRange={[initialMinPrice, initialMaxPrice]}
          onRangeChanged={handleRangeChanged}
        />
      )}
    </Section>
  );
}

export default PriceFilter;
