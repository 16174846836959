import { React, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClassNames from 'classnames';
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CommonHeaderText from '../../../../packages/common-header-text';
import PopupDialogBox from '../../../../packages/popup-dialog-box';
import ResetPassword from '../../ResetPassword';
import { FORGET_PASSWORD } from '../../../../../config/EndpointHelper';
import { headers } from '../../../../../config/HeaderHelper';
import { API_ROOT } from '../../../../../config';
import axios from 'axios';
import DataHelper from '../../../../../config/DataHelper';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    fontFamily: 'Open Sans, sans-serif',
    borderRadius: '10px',
  },
  textInput: {
    margin: '0 0 16px 0',
    display: 'block',
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      height: '42px',
      color: '#171717',
    },
    '& .MuiOutlinedInput-input': {
      padding: '20px',
    },
    '& .MuiInputLabel-formControl': {
      top: '-5px',
    },
  },
  formSubmit: {
    width: '100%',
    fontSize: '16px',
    backgroundColor: '#0056B2',
    color: '#fff',
    textTransform: 'capitalize',
    // transition: 'all 0.3s ease-in-out',
    margin: '32px 0 20px 0',
    borderRadius: '4px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0056B2',
      color: '#FFFFFF',
    },
  },

  formSignupText: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: '11px',
  },

  tabSwitch: {
    border: '1px solid #999999',
    borderRadius: '30px',
    height: '45px',
    margin: '0 0 10px 0',
  },
  tabRoot: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#EE6A28',
      color: '#fff',
      boxShadow: 'none',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    '& .MuiButtonBase-root': {
      border: '30px',
      backgroundColor: '#fff',
    },
  },
  countrySelect: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px 0 0 10px',
    },
  },
  phoneInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0 10px 10px 0',
    },
  },
  socialMuiButton: {
    '&.MuiButton-contained': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      boxShadow: '0px 6px 20px #0056b240 !important',
    },
  },
  loginFormpd: {
    padding: '32px 0 0 0',
  },
  specialText: {
    cursor: 'pointer',
    color: '#001AC0',
  },

  specialText1: {
    cursor: 'pointer',
    color: '#0056B2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  loadingButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -4,
    marginLeft: -12,
  },
  lineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: '1rem 0',
  },
  horizontalLine: {
    height: '1px',
    width: '100%',
    backgroundColor: '#D9D9D9',
    flexGrow: 1,
    flexShrink: 1,
  },
  lineText: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '700',
    padding: '0 1rem',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontFamily: 'sans-serif',
    marginLeft: '14px',
  },
}));

const ForgotPassword = props => {
  const { root_style, root_class, onChange, closePopup } = props;
  const classes = useStyles();
  const [flag, setFlag] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const sendOtp = async () => {
    let payload = {
      email: email,
      page: 'resetinput',
    };
    try {
      axios.post(API_ROOT + FORGET_PASSWORD, DataHelper(payload), {
        headers: headers,
      });
    } catch (error_forgotPass) {
      if (error_forgotPass.response.status == 400) {
        setError(error_forgotPass.response.data.message);
      }
    }
  };

  const handleSubmit = () => {
    if (email == '') {
      setError('Please Enter your Email');
    } else {
      sendOtp();
      setFlag(true);
    }
  };

  return (
    <>
      <div
        className={ClassNames([classes.root, root_class])}
        style={root_style}>
        <>
          {flag == false ? (
            <Grid container style={{ padding: '38px' }}>
              <Grid item xs={12}>
                <CommonHeaderText
                  text={`Forgot Password`}
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '28px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <CommonHeaderText
                  text={
                    <span>
                      Please enter the email address associated with your
                      account and We will email you a link to reset your
                      password.
                    </span>
                  }
                  variant="h5"
                  style={{
                    paddingTop: '12px',
                    color: '#171717',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <form className={classes.loginFormpd}>
                  <div
                    className={classes.textInput}
                    style={{ marginBottom: '0' }}>
                    <TextField
                      data-cy="email"
                      name="email"
                      id="email"
                      label="Enter Email Address"
                      value={email}
                      variant="outlined"
                      onChange={e => setEmail(e.target.value)}
                      error={error}
                      style={{ width: '100%' }}
                      helperText={error}
                    />
                  </div>

                  <div
                    className={classes.loadingButtonWrapper}
                    style={{
                      marginTop: '16px',
                    }}>
                    <Button
                      className={classes.formSubmit}
                      variant="contained"
                      onClick={handleSubmit}>
                      Reset Password
                    </Button>
                  </div>
                  <CommonHeaderText
                    text={
                      <span
                        onClick={() => onChange()}
                        className={classes.specialText1}>
                        <img
                          data-cy="arrow-round-forward"
                          width="18px"
                          height="18px"
                          src="/static/images/ionic-ios-arrow-round-backward-back.svg"
                          alt="arrow-round-backward"
                          style={{ marginRight: '13px' }}
                        />{' '}
                        Back
                      </span>
                    }
                    variant="h5"
                    style={{
                      color: '#171717',
                      fontSize: '14px',
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: '400',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          ) : (
            <Grid container style={{ padding: '94px 49px' }}>
              <Grid item xs={12}>
                <CommonHeaderText
                  text={`Request sent successfully`}
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '28px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <CommonHeaderText
                  text={
                    <span>
                      We have sent a confirmation email to{' '}
                      <span style={{ fontWeight: 'bold' }}>{email}.</span>
                      <span style={{ display: 'block' }}>
                        Please check your email.
                      </span>
                    </span>
                  }
                  variant="h5"
                  style={{
                    paddingTop: '12px',
                    color: '#171717',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <form className={classes.loginFormpd}>
                  <CommonHeaderText
                    text={
                      <span
                        onClick={closePopup}
                        className={classes.specialText1}>
                        <img
                          data-cy="arrow-round-forward"
                          width="18px"
                          height="18px"
                          src="/static/images/ionic-ios-arrow-round-backward-back.svg"
                          alt="arrow-round-backward"
                          style={{ marginRight: '13px' }}
                        />{' '}
                        Back
                      </span>
                    }
                    variant="h5"
                    style={{
                      color: '#171717',
                      fontSize: '14px',
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: '400',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          )}
          {/* End request sent successfully */}
          <PopupDialogBox
            root_class="custom-Login-modal"
            open={false}
            modal_content={<ResetPassword />}
          />
        </>
      </div>
    </>
  );
};

export default ForgotPassword;
