import { useState } from 'react';
import Section from '../Section';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';

function ModuleFilter(props) {
  const { rootClass, modules, defaultSelection, onChange } = props;
  const [value, setValue] = useState(defaultSelection ?? 'buy');
  const handleChange = event => {
    setValue(event.target.value);
    if (onChange && typeof onChange === 'function') {
      onChange(event.target.value);
    }
  };

  return (
    <Section title="I want to" rootClass={rootClass}>
      <Grid container>
        <Grid item>
          <RadioGroup
            style={{ flexFlow: 'row nowrap' }}
            aria-label="select module type"
            name="moduleType"
            value={value}
            onChange={handleChange}>
            {modules.map(({ label, value: moduleValue }) => (
              <FormControlLabel
                key={moduleValue}
                value={moduleValue}
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Section>
  );
}

export default ModuleFilter;
