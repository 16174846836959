const footerMainTitle = {
  '& h4': {
    fontSize: '16px!important',
    fontWeight: '700',
    color: '#0056B2',
    letterSpacing: '-0.32px',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '14px',
    marginTop: '30px',
  },
};
const footerSubTitle = theme => ({
  '& h4': {
    fontSize: '16px!important',
    fontWeight: '700',
    color: '#0056B2',
    letterSpacing: '-0.32px',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '0px',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
      height: '52px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '52px',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
    },
  },
  '& h5,li,a': {
    fontSize: '13px!important',
    fontWeight: '700',
    color: '#171717',
    letterSpacing: '-0.26px',
    lineHeight: '26px',
    fontFamily: 'open sans',
    textAlign: 'left',
    marginBottom: '0px',
  },
  '& ul': {
    paddingTop: '5px!important',
  },
});
const footerLinks = {
  '& li ,a': {
    padding: '4px 0px!important',
    fontWeight: '400!important',
  },

  'a:hover': {
    color: '#0056b2!important',
  },
};
const horizontalLine = theme => ({
  width: '100% !important',
  backgroundColor: 'rgb(224 224 224)!important',
  margin: '20px 0px 0px 0px!important',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  // [theme.breakpoints.down('lg')]: {
  //   display:'none',

  // },
});

const horizontalLine1 = theme => ({
  width: 'auto !important',
  backgroundColor: 'rgb(224 224 224)!important',
  margin: '20px 0px 0px 0px!important',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});
const wrapper = {
  maxHeight: '300px',
  display: 'flex',
  overflowX: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};
const item = {
  minWidth: '200px',
  height: 'auto',
};
const columnReverse = theme => ({
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse!important',
  },
});
const tabFooter = theme => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'inline-block!important',
  },
});
const contentWidth = theme => ({
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%!important',
  },
});

const tabFooter1 = theme => ({
  [theme.breakpoints.down('lg')]: {
    paddingRight: '10px',
  },
});

export default {
  footerMainTitle,
  footerSubTitle,
  footerLinks,
  horizontalLine,
  horizontalLine1,
  wrapper,
  item,
  columnReverse,
  tabFooter,
  contentWidth,
  tabFooter1,
};
