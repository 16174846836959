import { useCallback, useEffect, useState } from 'react';
import { Button, createTheme, ThemeProvider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { getAdvancedFilters } from '../../../redux/actions/search';
import useSearchFilter from '../../../Hooks/useSearchFilter';
import PopupDialogBox from '../../packages/popup-dialog-box';
import useDisableBodyScroll from '../../packages/use-disable-body-scroll';
import useStyles from './styles';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import LocalityFilter from './LocalityFilter';
import ModuleFilter from './ModuleFilter';
import PropertyTypeFilter from './PropertyTypeFilter';
import SaleTypeFilter from './SaleTypeFilter';
import UnitTypeFilter from './UnitTypeFilter';
import ConfigurationFilter from './ConfigurationFilter';
import PossessionFilter from './PossessionFilter';
import AmenitiesFilter from './AmenitiesFilter';
import FacilitiesFilter from './FacilitiesFilter';
import BankFilter from './BankFilter';
import BuilderRatingFilter from './BuilderRatingFilter';
import PriceFilter from './PriceFilter';
import AvailableFilter from './AvailableFilter';
import FurnishingFilter from './FurnishingFilter';
import CarpetAreaFilter from './CarpetAreaFilter';
import PopularCitiesFilter from './PopularCitiesFilter';
import PopularPartsFilter from './PopularPartsFilter';
import axios from '../../../redux/actions/axios-instance-config';
import { API_ROOT } from '../../../config';
import {
  GLOBAL_SEARCH_DATA_API,
  SEARCH_FILTER_API,
} from '../../../config/EndpointHelper';
import { useRouter } from 'next/router';
import {
  //  getPropertyDetailsURL,
  gotoPropertyDetailsPage,
} from '../../util';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0056B2',
    },
    secondary: {
      main: '#DFDBFD',
    },
  },
});
const SCREEN_MAIN = 'main';
const SCREEN_CITIES = 'cities';
const SCREEN_PARTS = 'popularParts';

function SearchFilterPopup(props) {
  const {
    city,
    moduleType,
    stateInitializer,
    advancedFilters,
    popularCities,
    getAdvancedFilters,
    selectedCity,
    popularParts,
    popularLocalities,
    popularSubLocalities,
    popularProperties,
    onCloseClick,
    isOpen,
    shallowSearch = false,
    showSearchScreen,
  } = props;
  const classes = useStyles();
  const router = useRouter();
  useDisableBodyScroll(isOpen);
  const initializer =
    stateInitializer ??
    (state => {
      state.propertyTypes = ['appartment'];
      if (moduleType) {
        state.module = moduleType;
      }
      state.city_name = city?.name ?? 'mumbai';
      state.city_id = city?.id ?? 1;
    });
  const { settings, reset, search, exportFilter } =
    useSearchFilter(initializer);
  const initialScreen = showSearchScreen ? SCREEN_PARTS : SCREEN_MAIN;
  const [screen, setScreen] = useState(initialScreen);
  const [popularData, setPopularData] = useState({
    parts: popularParts,
    localities: popularLocalities,
    subLocalities: popularSubLocalities,
    properties: popularProperties,
  });

  useEffect(() => {
    setScreen(initialScreen);
  }, [initialScreen]);

  const [initialLocalities] = useState(
    [].concat(settings.popularParts, settings.locations, settings.subLocations)
  );
  const [selectedLocalities, setSelectedLocalities] =
    useState(initialLocalities);
  const [resultCount, setResultCount] = useState(0);

  const resetFilterState = (withoutInitializer = false) => {
    if (withoutInitializer) {
      reset();
      setSelectedLocalities([]);
    } else {
      reset(initializer);
      setSelectedLocalities(initialLocalities);
    }
  };

  const updatePopularData = (parts, localities, subLocalities, properties) => {
    const newData = {
      parts,
      localities,
      subLocalities,
      properties,
    };
    setPopularData(newData);
  };

  useEffect(() => {
    if (settings.module === 'rent' && isOpen) {
      getAdvancedFilters({ module_type: settings.module });
    } else {
      if (isOpen) getAdvancedFilters();
    }
    // TO-DO : for commercial property type on URL
    if (router.asPath.includes('commercial-properties')) {
      settings.propertyTypes =
        settings.propertyTypes.length == 0
          ? ['commercial_office']
          : settings.propertyTypes ?? [];
    }
  }, [settings.module, isOpen]);

  useEffect(() => {
    if (selectedCity.id && selectedCity.name) {
      settings.cityId = selectedCity.id;
      settings.cityName = selectedCity.name;
    }
  }, [selectedCity]);

  useEffect(() => {
    const fetchPopularData = async cityId => {
      const response = await axios.get(`${API_ROOT}${GLOBAL_SEARCH_DATA_API}`, {
        params: {
          selectedCity: cityId,
        },
      });
      if (response.status === 200) {
        const { popularPart, localities, sub_localities, properties } = response
          .data.data ?? {
          popularPart: '',
          localities: '',
          sub_localities: '',
          properties: '',
        };
        updatePopularData(popularPart, localities, sub_localities, properties);
      }
    };
    fetchPopularData(settings.cityId);
  }, [settings.cityId]);

  useEffect(() => {
    updatePopularData(
      popularParts,
      popularLocalities,
      popularSubLocalities,
      popularProperties
    );
  }, [
    popularParts,
    popularLocalities,
    popularSubLocalities,
    popularProperties,
  ]);

  const getSearchCount = useCallback(async (params, limit, skip) => {
    try {
      const filterParams = params;
      filterParams.limit = limit;
      filterParams.skip = skip;
      const response = await axios.post(
        `${API_ROOT}${SEARCH_FILTER_API}`,
        filterParams
      );
      if (response.status === 200) {
        return response.data.count;
      }
    } catch (err) {
      console.error(err);
    }
    return 0;
  }, []);

  useEffect(() => {
    if (isOpen) { getSearchCount(exportFilter(), 1, 0).then(val => setResultCount(val)) };
  }, [
    settings.amenities,
    settings.banks,
    settings.bhks,
    settings.builderRatings,
    settings.carpetArea,
    settings.cityId,
    settings.cityName,
    settings.developers,
    settings.facilities,
    settings.furnishingStatus,
    settings.investmentRatings,
    settings.leaseType,
    settings.livabilityRatings,
    settings.locations,
    settings.module,
    settings.popularParts,
    settings.possessionYears,
    settings.price,
    settings.propertyTypes,
    settings.recommendedRatings,
    settings.saleType,
    settings.subLocations,
    settings.unitTypes,
    isOpen
  ]);

  const handlePopupClose = () => {
    setScreen(initialScreen);
    resetFilterState();
    if (onCloseClick && typeof onCloseClick === 'function') {
      onCloseClick();
    }
  };

  const handleCityChanged = cityMain => {
    resetFilterState(true);
    settings.cityId = cityMain.id ?? '1';
    settings.cityName = cityMain.name ?? 'mumbai';
    setScreen(SCREEN_MAIN);
  };

  const handleLocalitiesAdd = newLocalities => {
    const parts = [];
    const locations = [];
    const subLocations = [];

    // Combine selected localities in previous selection
    const localities = [...selectedLocalities];
    newLocalities.forEach(({ id, name, type }, index) => {
      const found = localities.find(
        locality =>
          locality.id === id && locality.name === name && locality.type === type
      );
      if (found) return;
      localities.push(newLocalities[index]);
    });

    localities.forEach(locality => {
      const { type } = locality;
      if (type === 'popularPart') {
        parts.push(locality);
      }
      if (type === 'localities') {
        locations.push(locality);
      }
      if (type === 'sub_localities') {
        subLocations.push(locality);
      }
    });
    settings.popularParts = parts;
    settings.locations = locations;
    settings.subLocations = subLocations;
    setSelectedLocalities(localities);
  };

  const handleLocalityDelete = locality => {
    const index = selectedLocalities.findIndex(
      i =>
        i.id === locality.id &&
        i.name === locality.name &&
        i.type === locality.type
    );
    if (index < 0) return;
    const newSelectedLocalities = [...selectedLocalities];
    newSelectedLocalities.splice(index, 1);
    setSelectedLocalities(newSelectedLocalities);
    if (locality.type === 'popularPart') {
      const idx = settings.popularParts.findIndex(
        ({ id }) => id === locality.id
      );
      const newPopularParts = [...settings.popularParts];
      newPopularParts.splice(idx, 1);
      settings.popularParts = newPopularParts;
      return;
    }
    if (locality.type === 'localities') {
      const idx = settings.locations.findIndex(({ id }) => id === locality.id);
      const newLocations = [...settings.locations];
      newLocations.splice(idx, 1);
      settings.locations = newLocations;
      return;
    }
    if (locality.type === 'sub_localities') {
      const idx = settings.subLocations.findIndex(
        ({ id }) => id === locality.id
      );
      const newSubLocations = [...settings.subLocations];
      newSubLocations.splice(idx, 1);
      settings.subLocations = newSubLocations;
      return;
    }
  };

  const handlePropertyClick = property => {
    // Localities selection screen only displays `buy` module properties
    // due to which generated link must use `buy` module only.
    gotoPropertyDetailsPage({
      module_type: 'buy',
      city_name: settings.cityName,
      property_name: property.name,
      property_id: property.id,
      localityName: property.localityName,
    });
  };

  function getButtonLabel(resultCount) {
    if (resultCount === 0) {
      return 'No properties found';
    } else if (resultCount === 1) {
      return `View ${resultCount} Property`;
    } else {
      return `View ${resultCount} Properties`;
    }
  }

  // Call the function to get the button label
  let buttonLabel = getButtonLabel(resultCount);

  return (
    <ThemeProvider theme={theme}>
      {/* Main Screen */}
      <PopupDialogBox
        open={isOpen && screen === SCREEN_MAIN}
        root_class={classes.root}>
        <TopBar
          currentCity={settings.cityName}
          onClose={handlePopupClose}
          onChangeClick={() => setScreen(SCREEN_CITIES)}
        />
        <LocalityFilter
          data={selectedLocalities}
          onSearchClick={e => {
            setScreen(SCREEN_PARTS);
            e.stopPropagation();
          }}
          onDelete={handleLocalityDelete}
        />
        <ModuleFilter
          modules={advancedFilters.moduleType}
          defaultSelection={settings.module}
          onChange={newValue => {
            settings.module = newValue;
            // Reset selected price range
            settings.price = [];
            settings.saleType = ''; // Reset sale type
            settings.possessionYears = []; // Reset possession years
            settings.banks = []; // Reset approved banks
            settings.builderRatings = []; // Reset builder ratings
            settings.leaseType = []; //reset Availability
            settings.furnishingStatus = []; //Reset Availability
            settings.bhks = []; // Reset bhks type
            settings.unitTypes = []; // Reset unit type
            settings.propertyTypes = []; // Reset propertyTypes type
            settings.carpetArea = [];
            settings.amenities = [];
            settings.facilities = [];
          }}
        />
        <PropertyTypeFilter
          loading={advancedFilters.loading}
          data={advancedFilters.propertyType}
          defaultSelected={settings.propertyTypes[0]}
          onChange={value => (settings.propertyTypes = [value])}
        />
        {settings.module === 'buy' && (
          <SaleTypeFilter
            loading={advancedFilters.loading}
            data={advancedFilters.saletype}
            defaultSelected={settings.saleType}
            onChange={value => (settings.saleType = value)}
          />
        )}
        {settings.propertyTypes.length > 0 && (
          <UnitTypeFilter
            loading={advancedFilters.loading}
            data={advancedFilters.unitType}
            defaultSelected={settings.unitTypes}
            propertyType={
              advancedFilters.propertyType.find(
                item => item.value === settings.propertyTypes[0]
              )?.label
            }
            onChange={values => (settings.unitTypes = values)}
          />
        )}
        {settings.propertyTypes[0] === 'appartment' && (
          <ConfigurationFilter
            loading={advancedFilters.loading}
            data={advancedFilters.configurations}
            defaultSelected={settings.bhks}
            onChange={values => (settings.bhks = values)}
          />
        )}
        <PriceFilter
          loading={advancedFilters.loading}
          data={advancedFilters.price}
          defaultPriceRange={[settings.minPrice, settings.maxPrice]}
          onChange={(minPrice, maxPrice) => {
            settings.price = [minPrice, maxPrice];
          }}
        />
        {settings.module === 'buy' && (
          <PossessionFilter
            loading={advancedFilters.loading}
            data={advancedFilters.possessionYears}
            defaultSelected={settings.possessionYears}
            onChange={values => (settings.possessionYears = values)}
          />
        )}
        {settings.module === 'rent' && (
          <FurnishingFilter
            loading={advancedFilters.loading}
            data={advancedFilters.furnishingStatus}
            defaultSelected={settings.furnishingStatus}
            onChange={values => (settings.furnishingStatus = values)}
          />
        )}
        {settings.module === 'rent' && (
          <AvailableFilter
            loading={advancedFilters.loading}
            data={advancedFilters.leaseType}
            defaultSelected={settings.leaseType}
            onChange={values => (settings.leaseType = values)}
          />
        )}
        <CarpetAreaFilter
          loading={advancedFilters.loading}
          data={advancedFilters.carpetArea}
          defaultCarpetAreaRange={[
            settings.minCarpetArea,
            settings.maxCarpetArea,
          ]}
          onChange={(minCarpetArea, maxCarpetArea) => {
            settings.carpetArea = [minCarpetArea, maxCarpetArea];
          }}
        />
        <AmenitiesFilter
          loading={advancedFilters.loading}
          data={advancedFilters.aminities}
          defaultSelected={settings.amenities}
          onChange={values => (settings.amenities = values)}
        />
        <FacilitiesFilter
          loading={advancedFilters.loading}
          data={advancedFilters.facilities}
          defaultSelected={settings.facilities}
          onChange={values => (settings.facilities = values)}
        />
        {settings.module === 'buy' && (
          <BankFilter
            loading={advancedFilters.loading}
            data={advancedFilters.approvedbyBank}
            defaultSelected={settings.banks}
            onChange={values => (settings.banks = values)}
          />
        )}
        {settings.module === 'buy' && (
          <BuilderRatingFilter
            loading={advancedFilters.loading}
            data={[{ label: 'Any', value: 0 }].concat(
              advancedFilters.builderRating
            )}
            defaultSelected={settings.builderRatings}
            onChange={values => {
              settings.builderRatings = values.includes(0) ? [] : values;
            }}
          />
        )}
        <BottomBar
          className={classes.bottomFooter}
          style={{
            minHeight: '54px',
            position: 'relative',
            background: '#f8f8ff',
            width: '100%',
            top: '0px',
            bottom: '-7px',
            padding: '10px',
          }}>
          <Button
            className={classes.bottomAction}
            variant="contained"
            color="primary"
            disableElevation={true}
            onClick={() => search(shallowSearch)}
            disabled={resultCount === 0}>
            {buttonLabel}
          </Button>
        </BottomBar>
      </PopupDialogBox>

      {/* Cities Selection Screen */}
      {isOpen && screen === SCREEN_CITIES && (
        <PopupDialogBox open={true} root_class={classes.root}>
          <TopBar
            currentCity={settings.cityName}
            onClose={() => setScreen(SCREEN_MAIN)}
            closeIcon={<ArrowBackIcon />}
            onChangeClick={() => setScreen(SCREEN_CITIES)}
          />
          <PopularCitiesFilter
            rootClass={classes.popularCitiesRoot}
            cities={popularCities}
            defaultCitySelected={settings.cityId}
            onNextClick={handleCityChanged}
          />
        </PopupDialogBox>
      )}

      {/* Popular Parts and Localities Selection Screen */}
      {isOpen && screen === SCREEN_PARTS && (
        <PopupDialogBox open={true} root_class={classes.root}>
          <TopBar
            currentCity={settings.cityName}
            onClose={() => setScreen(SCREEN_MAIN)}
            closeIcon={<ArrowBackIcon />}
            onChangeClick={() => setScreen(SCREEN_CITIES)}
          />
          <PopularPartsFilter
            moduleType={moduleType}
            rootClass={classes.popularPartsRoot}
            city={{ id: settings.cityId, name: settings.cityName }}
            parts={popularData.parts}
            localities={popularData.localities}
            subLocalities={popularData.subLocalities}
            projects={popularData.properties}
            onNext={localities => {
              handleLocalitiesAdd(localities);
              setScreen(SCREEN_MAIN);
            }}
            onPropertyClick={handlePropertyClick}
          />
        </PopupDialogBox>
      )}
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  advancedFilters: state.advancedFiltersReducer,
  popularCities: state.headerReducer.cities,
  selectedCity: state.headerReducer.global_selected_city,
  popularParts: state.headerReducer.global_search_data?.popularPart,
  popularLocalities: state.headerReducer.global_search_data?.localities,
  popularSubLocalities: state.headerReducer.global_search_data?.sub_localities,
  popularProperties: state.headerReducer.global_search_data?.properties,
});
const mapDispatchToProps = {
  getAdvancedFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilterPopup);
