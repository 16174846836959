import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  chipItem: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '0.3rem',
    textTransform: 'capitalize',
  },
  searchIcon: {
    marginLeft: '-7px',
  },
  scrollContainer: {
    padding: '0.3rem 0',
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0.2rem',
  },
  searchBox: {
    backgroundColor: '#fff',
  },
}));
