import React, { useState, Fragment } from 'react';
import CommonModal from '@/components/packages/common-modal';
import ClassNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFFCC',
    border: '1px solid #0056B21A',
    padding: '10px 15px',
    cursor: 'pointer',
  },
}));

const HeaderButtonIconText = props => {
  const [bg_color, setBgColor] = useState(false);

  const {
    text,
    color,
    width,
    height,
    activeColor,
    inactiveColor,
    icon,
    fontSize,
    modal_content,
    modal_top,
    modal_left,
    modal_width,
    with_modal,
    root_class,
  } = props;

  const handleHover = () => {
    bg_color ? setBgColor(false) : setBgColor(true);
  };

  const [is_open, setIsOpen] = useState(false);

  function openModal() {
    {
      with_modal ? setIsOpen(true) : null;
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  const classes = useStyles();

  return (
    <Fragment>
      <div
        data-cy={props['data-cy']}
        className={ClassNames([classes.root, root_class])}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        width={width}
        height={height}
        style={{
          textTransform: 'capitalize',
          color: color,
          width: width,
          height: height,
          fontSize: fontSize,
          backgroundColor: bg_color ? activeColor : inactiveColor,
        }}
        onClick={openModal}>
        {text}&nbsp;{icon}
      </div>
      {with_modal && (
        <CommonModal
          handleClose={closeModal}
          open={is_open}
          modal_top={modal_top}
          modal_left={modal_left}
          modal_width={modal_width}
          modalContent={modal_content}
        />
      )}
    </Fragment>
  );
};

export default HeaderButtonIconText;
