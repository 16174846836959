import { API_ROOT } from '../config';
import {
  GET_VIRTUAL_URL,
  GET_PROPERTY_DETAILS_API,
  LOGOUT_USER_API,
} from '../config/EndpointHelper';
import axios from 'axios';

export const getMetaDataApi = async params => {
  let data = await fetch(API_ROOT + GET_VIRTUAL_URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ url: params }),
  });
  let response = await data.json();
  return response;
};
export const getFaqData = async params => {
  const property_id = params[4];
  const module_type = params[0];
  const city_name = params[2];
  let data = await fetch(API_ROOT + GET_PROPERTY_DETAILS_API, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      p_id: property_id,
      module_type: module_type,
      city_name: city_name,
      section: 'faq',
    }),
  });
  let response = await data.json();
  return response;
};

export const checkBackendLogin = async (token, fromadmin) => {
  if (token && fromadmin) {
    try {
      let response = await axios.get(
        API_ROOT +
          LOGOUT_USER_API +
          (fromadmin ? '?fromadmin=' + fromadmin : ''),
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.status == 200) {
        return response.data.data;
      }
      return response;
    } catch (err) {
      if (err.response.status == 401) {
        return err.response.data.message;
      }
    }
  } else {
    return {};
  }
};
