import { AppBar, Toolbar } from '@material-ui/core';
import useStyles from './styles';

function BottomBar(props) {
  const { position = 'sticky' } = props;
  const classes = useStyles();
  return (
    <AppBar className={classes.root} position={position} color="default">
      <Toolbar>{props.children}</Toolbar>
    </AppBar>
  );
}

export default BottomBar;
