import Section from '../Section';
import useStyles from './styles';
import {
  Chip,
  Grid,
  InputAdornment,
  Typography,
  TextField,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useEffect, useRef } from 'react';

function LocalityFilter(props) {
  const { rootClass, data = [], onDelete, onSearchClick } = props;
  const classes = useStyles();
  const apiRef = useRef();
  useEffect(() => {
    const lastItem = data.slice(-1)[0];
    const itemId = `${lastItem?.name}-${lastItem?.type}-${lastItem?.id}`;
    apiRef.current?.scrollToItem?.(
      apiRef.current?.getItemElementById(itemId),
      'smooth'
    );
  }, []);
  return (
    <Section title="Locality" variant="secondary" rootClass={rootClass}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            placeholder="Search Locality, Property, Builder"
            onChange
            onClick={e =>
              onSearchClick &&
              typeof onSearchClick === 'function' &&
              onSearchClick(e)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {data.length > 0 && (
          <Grid item xs={12}>
            <ScrollMenu
              scrollContainerClassName={classes.scrollContainer}
              apiRef={apiRef}>
              {data.map(locality => (
                <Chip
                  itemId={`${locality.name}-${locality.type}-${locality.id}`}
                  key={`${locality.name}-${locality.id}`}
                  className={classes.chipItem}
                  label={
                    <Typography variant="body2" noWrap={true}>
                      {locality.name}
                    </Typography>
                  }
                  deleteIcon={<ClearIcon />}
                  onDelete={() => {
                    onDelete &&
                      typeof onDelete === 'function' &&
                      onDelete(locality);
                  }}
                />
              ))}
            </ScrollMenu>
          </Grid>
        )}
      </Grid>
    </Section>
  );
}

export default LocalityFilter;
