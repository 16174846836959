import Section from '../Section';
import ChipGroup from '../ChipGroup';

function UnitTypeFilter(props) {
  const { rootClass, loading, data, propertyType, defaultSelected, onChange } =
    props;
  const itemMap = new Map();
  data.forEach(({ label, value, propType }) => {
    if (propType === propertyType) itemMap.set(label, value);
  });

  return (
    <Section title="Unit Type" rootClass={rootClass}>
      {loading && <p>Loading...</p>}
      {!loading && (
        <ChipGroup
          items={[...itemMap.keys()]}
          defaultSelection={defaultSelected.map(
            value => data.find(i => i.value === value)?.label
          )}
          onChange={selected =>
            onChange &&
            typeof onChange === 'function' &&
            onChange(selected.map(s => itemMap.get(s)))
          }
        />
      )}
    </Section>
  );
}

export default UnitTypeFilter;
