import useWindow from '@/hooks/useWindow';
import { useState, useEffect, useRef } from 'react';

const useStickyHeader = props => {
  const [show_sticky_header, setShowStickyHeader] = useState(false);
  const hasCreatedEventListner = useRef(false);
  let hasWindow = useWindow();
  useEffect(() => {
    const handleScroll = () => {
      if (hasWindow && window.scrollY > props) {
        setShowStickyHeader(true);
      }
      if (hasWindow && window.scrollY < props) {
        setShowStickyHeader(false);
      }
    };

    if (hasWindow && !hasCreatedEventListner.current && props) {
      window.addEventListener(`scroll`, handleScroll);
      hasCreatedEventListner.current = true;
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  }, [props]);

  return [show_sticky_header];
};

export default useStickyHeader;
