import React from 'react';
import HorizontalScrollButton from '../../packages/horizontal-scroll-button';
import ClassNames from 'classnames';
import styles from './HorizontalScrollButtons.module.scss';

const HorizontalScrollButtons = props => {
  const {
    loadPrevProjects,
    loadNextProjects,
    firstdisabled,
    disableNext,
    disablePrev,
  } = props;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <div style={{ marginTop: '10px' }}>
          <HorizontalScrollButton
            on_click={loadPrevProjects}
            style={{ margin: '4px' }}
            class_name={ClassNames([
              styles.navigateButtons,
              firstdisabled ? styles.disabled : '',
            ])}
            disablePrev={disablePrev}
            arrow_icon={
              <img
                src="/static/images/home-icons.svg"
                data-cy="arrow-round-backward"
                alt="arrow-round-backward"
                style={{
                  objectPosition: '-411px 0px',
                  objectFit: 'cover',
                  width: '30px',
                  height: '30px',
                }}
              />
            }
          />
        </div>
        <div style={{ marginTop: '14px' }}>
          <HorizontalScrollButton
            disableNext={disableNext}
            class_name={styles.navigateButtons}
            on_click={loadNextProjects}
            arrow_icon={
              <img
                src="/static/images/home-icons.svg"
                data-cy="arrow-round-forward"
                alt="arrow-round-forward"
                style={{
                  objectPosition: '-387px 1px',
                  objectFit: 'cover',
                  width: '30px',
                  height: '30px',
                }}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default HorizontalScrollButtons;
