/**
 * Module containing all configuration variables used in the project.
 *
 * @module config/index
 */
// For development and staging environments

/**
 * base url for the api calls
 */
const API_ROOT = process.env.NEXT_PUBLIC_API_ROOT;
/**
 * base url for the my account dashboard
 */
const MYACCOUNT_HOMEBAZAAR = process.env.NEXT_PUBLIC_MYACCOUNT_HOMEBAZAAR;
/**
 * api key for the google maps api
 */
const GOOGLE_API = process.env.NEXT_PUBLIC_GOOGLE_API;
/**
 * base url for the knowledge center
 */
const KNOWLEDGE_CENTER = process.env.NEXT_PUBLIC_KNOWLEDGE_CENTER;
/**
 * Google Recaptcha URL
 */
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTACHA_KEY;
/**
 * host address running the application (e.g. http://localhost:3000/)
 */
const DOMAIN_NAME = process.env.NEXT_PUBLIC_DOMAIN_NAME;
/**
 * flag to decide whether to enable/disable hotjar script
 */
const HOTJAR_ENABLED = ['yes', 'true'].includes(
  process.env.NEXT_PUBLIC_HOTJAR_ENABLED?.toLocaleLowerCase().trim()
);
/**
 * flag to decide whether to enable/disable ahrefs script
 */
const AHREFS_META_ENABLED = ['yes', 'true'].includes(
  process.env.NEXT_PUBLIC_AHREFS_META_ENABLED?.toLocaleLowerCase().trim()
);
/**
 * flag to decide whether to disable application SEO indexing. If it is true,
 * noindex meta tag is added on all application pages to avoid SEO indexing.
 * This must be true in non-production environment (local,nginx & staging) only.
 */
const NOINDEX_ENABLED = ['yes', 'true'].includes(
  process.env.NEXT_PUBLIC_NOINDEX_ENABLED?.toLocaleLowerCase().trim()
);

const INITIAL_TIMER_VALUE = 3000;
const FINAL_TIMER_VALUE = 30000;
/**
 * enable/disable google tag manager script
 */
const NEXT_PUBLIC_GTM_CODE_SHOW = process.env.NEXT_PUBLIC_GTM_CODE_SHOW;

/**
 * flag to decide whether to use https or http based URL in the canonical link tag.
 * This must be true for nginx, staging and production environments only.
 */
const CANONICAL_HTTPS = ['yes', 'true'].includes(
  process.env.CANONICAL_HTTPS?.toLocaleLowerCase().trim()
);

/**
 * base url for PDP pages
 */
const API_ROOT_PDP_PAGE = process.env.API_ROOT_FOR_PDP_PAGE;

module.exports = {
  API_ROOT,
  MYACCOUNT_HOMEBAZAAR,
  KNOWLEDGE_CENTER,
  HOTJAR_ENABLED,
  AHREFS_META_ENABLED,
  GOOGLE_API,
  RECAPTCHA_SITE_KEY,
  DOMAIN_NAME,
  NOINDEX_ENABLED,
  INITIAL_TIMER_VALUE,
  FINAL_TIMER_VALUE,
  NEXT_PUBLIC_GTM_CODE_SHOW,
  CANONICAL_HTTPS,
  API_ROOT_PDP_PAGE,
};
