import React from 'react';
import ScrollMenu from './react-horizontal-scrolling-menu/build';

const HorizontalScroll = props => {
  const {
    data,
    componentRef,
    selected,
    onSelect,
    inertiaScrolling,
    inertiaScrollingSlowdown,
    itemClass,
    innerWrapperClass,
    dragging,
  } = props;
  return (
    <>
      <ScrollMenu
        alignCenter={false}
        ref={componentRef}
        data={data}
        wheel={false}
        selected={selected ? selected : false}
        onSelect={onSelect}
        hideSingleArrow={false}
        inertiaScrolling={inertiaScrolling}
        inertiaScrollingSlowdown={inertiaScrollingSlowdown}
        itemClass={itemClass}
        innerWrapperClass={innerWrapperClass}
        dragging={dragging}
      />
    </>
  );
};

export default HorizontalScroll;
