import { useState } from 'react';
import styles from './navbarDropdown.module.scss';
import ClassNames from 'classnames';
import HeaderButton from '@/components/packages/header-button';
import { MYACCOUNT_HOMEBAZAAR } from '@/config/index';
import useAccessToken from '../use-access-token';

const NavbarDropdown = props => {
  const {
    image,
    className,
    classNameHeader,
    classNameTxt,
    classBuysellrentbtn,
    root_class,
    color,
    bgColor,
    text,
    options,
    boxShadow,
  } = props;
  const [anchor, setAnchor] = useState(false);
  const [access_token] = useAccessToken();

  const handleMenu = event => {
    if (
      event.type === 'mouseover' &&
      !anchor &&
      event.target.classList.length > 0
    ) {
      setAnchor(true);
    } else if (event.type === 'mouseleave') {
      setAnchor(false);
    }
  };

  return (
    <div
      onMouseOver={handleMenu}
      onMouseLeave={handleMenu}
      className={ClassNames([styles.main_menu, root_class])}>
      <HeaderButton
        onMouseOver={handleMenu}
        data-cy={'header-btn-' + text}
        className={className}
        txtClassName={classNameTxt}
        style={{
          cursor: 'pointer',
          textTransform: 'capitalize',
        }}
        classNameHeader={classNameHeader}
        classBuysellrentbtn={classBuysellrentbtn}
        image={image}
        text={text}
        color={color}
        bgColor={bgColor}
        boxShadow={boxShadow}
        options={options ? true : false}
      />
      <div
        style={{
          height: '10px',
          position: 'relative',
        }}>
        <ul
          style={{
            visibility: anchor ? 'visible' : 'hidden',
            opacity: anchor ? 1 : 0,
            display: anchor ? '' : 'none',
          }}>
          {options &&
            options.map((option, index) => {
              const { text, link } = option;
              return (
                <li key={index}>
                  <a
                    href={`${
                      text == 'Sell Your Property' && access_token
                        ? MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') +
                          'properties' +
                          '/add'
                        : // 'https://myaccount.homebazaar.com/properties/add'
                        link.toLowerCase() == '/franchisee-model' ||
                          link.toLowerCase() == '/support'
                        ? '#'
                        : link
                    }`}>
                    {text}
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
export default NavbarDropdown;
