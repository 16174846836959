import { Paper, Typography } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'classnames';

function Section(props) {
  const { rootClass, title, variant, icon, childRootStyle = {} } = props;
  const classes = useStyles();
  return (
    <Paper
      className={clsx(
        variant === 'secondary'
          ? [classes.root, classes.bgSecondary, rootClass]
          : [classes.root, rootClass]
      )}
      square
      variant="outlined"
      style={childRootStyle}
      elevation={0}>
      {(icon || title) && (
        <div className={classes.head}>
          {icon}
          <Typography className={classes.heading}>{title}</Typography>
        </div>
      )}
      <div>{props.children}</div>
    </Paper>
  );
}

export default Section;
