import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
// import { makeStyles } from '@material-ui/core/styles';
import SidebarMainMenu from '@/components/packages/main-menu';
import ClassNames from 'classnames';
// import breakpoints from '@/data/breakpoints.json';
import useUserInfo from '@/components/packages/use-user-info';
import { MYACCOUNT_HOMEBAZAAR } from '@/config/index';
import classes from './main-navbar.module.scss';

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     color: 'white',
//   },
//   toolbar: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-start',
//     justifyContent: 'center',
//   },
//   toolbarWrapper: {
//     boxShadow: 'none',
//     padding: '0 10px',
//     [theme.breakpoints.down(breakpoints.sm)]: {
//       height: '51px',
//     },
//   },
//   marginX: {
//     padding: '0 20px',
//   },
//   filterButtonsContainer: {
//     border: '1px solid #0056B21A',
//     padding: '9px 20px !important',
//     boxShadow: '0px 4px 20px #0056b217',
//     backgroundColor: '#F2F5FC',
//     flexWrap: 'nowrap',
//     [theme.breakpoints.down(breakpoints.lg)]: {
//       padding: '9px 30px !important',
//     },
//   },
//   sellProperty: {
//     '& a': {
//       color: '#333333',
//       // fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
//       fontWeight: '500',
//       fontSize: '14px',
//     },

//     [theme.breakpoints.down(breakpoints.mdd)]: {
//       display: 'none',
//     },

//     color: '#333333',
//     // padding: '8px',
//     border: '1px solid #DEDEDE',
//     borderRadius: 0,
//     background: '#fff',
//     marginRight: '5px',
//     cursor: 'pointer',
//     position: 'relative',
//     height: '39px',

//     '&:hover': {
//       backgroundColor: 'rgba(0, 0, 0, 0.04)!important',
//     },
//   },
//   // commenting this for performance
//   // buysellbutton: {
//   //   [theme.breakpoints.down('md')]: {
//   //     display: 'none',
//   //   },
//   // },
//   verticalLine: {
//     height: '100%',
//     borderLeft: '1px solid #ddd',
//     position: 'absolute',
//     left: '34px',
//     [theme.breakpoints.down(breakpoints.llg)]: {
//       display: 'none',
//     },
//     [theme.breakpoints.down('md')]: {
//       display: 'none',
//     },
//   },

//   postDetails: {
//     '& img': {
//       marginRight: '10px',
//       [theme.breakpoints.down('md')]: {
//         display: 'none',
//       },
//       [theme.breakpoints.down(breakpoints.llg)]: {
//         display: 'none',
//       },
//       [theme.breakpoints.down(breakpoints.mdl)]: {
//         display: 'block',
//         marginRight: '0px',
//       },
//     },
//     '& a': {
//       [theme.breakpoints.down(breakpoints.mdl)]: {
//         display: 'none',
//       },
//     },
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     padding: '6px',
//   },
//   sidemenuButton: {
//     marginTop: '1px',
//     // marginBottom: '5px',
//     padding: '4px 0px',
//     [theme.breakpoints.down(breakpoints.sm)]: {
//       marginTop: '8px',
//     },
//   },
// }));
const Navbar = props => {
  // const classes = useStyles();

  const {
    logo,
    navbar_buttons,
    city_search,
    sidebar_button,
    menu_items,
    all_filter_buttons,
    sidebar_right_icons,
    sidebar_root_class,
    position,
    is_open,
    pageType,
    small,
    large,
    is_desktop,
  } = props;

  const [open, setOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [user_info] = useUserInfo();

  /* Function to Check if user is Logged In */
  function isUserInfo(user_info_login) {
    // user_info object is empty then user is not logged in
    if (user_info_login == undefined) {
      return true;
    }
    return Object.keys(user_info_login).length === 0;
  }

  useEffect(() => {
    if (!showBanner) {
      setShowBanner(true);
    }
  }, [showBanner]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  //  ** for header on mobile **
  // let mobileStyle = {
  //   marginTop: '17px',
  //   position: 'absolute',
  // };

  const renderSideMenuBar = () => {
    return ReactDOM.createPortal(
      <SidebarMainMenu
        root_class={sidebar_root_class}
        handleClick={handleClick}
        handleClickAway={handleClickAway}
        menuitems={menu_items}
        open_control={open}
        sidebar_right_icons={sidebar_right_icons}
      />,
      document.body
    );
  };
  const [isJsload, setisJsload] = useState(false);
  useEffect(() => {
    setisJsload(true);
  }, []);

  return (
    <Fragment>
      <AppBar
        className={ClassNames([
          'toolbarWrapper',
          all_filter_buttons ? '' : classes.toolbarWrapper,
        ])}
        style={{
          background: '#F9F9F9',
          borderBottom: pageType ? '1px solid #D9E5F4' : 'none',
        }}
        position={position}>
        <Toolbar className={ClassNames([classes.toolbar])}>
          <Grid
            className={all_filter_buttons ? classes.marginX : ''}
            justifyContent="space-between" // Add it here :)
            alignItems="center"
            spacing={3}
            container>
            <Grid
              item
              xs={10}
              sm={3}
              md={2}
              lg={2}
              xl={2}
              style={
                isJsload
                  ? {
                    marginTop: small ? '12px' : large ? '8px' : '0px',
                    position: large ? 'relative' : undefined
                  }
                  : {
                    marginTop: '10px!important',
                      position: city_search? 'absolute' :'relative',
                    }
              }>
              <Grid item>{logo}</Grid>
            </Grid>
            {/* {!is_open && city_search ?(
              <Grid
                item
                xs={city_search ? 4 : 2} //TO-CHECK : replaces value for debug city_search ? 4 : 0
                sm={city_search ? 7 : 2}
                md={city_search ? 5 : 2}
                lg={city_search ? 4 : 2}
                xl={city_search ? 5 : 2}
                style={
                  isJsload
                    ? { marginLeft: 'auto' }
                    : {
                        marginLeft: '12rem',
                      }
                }>
                <Grid container>
                  <Grid item className="search-container">
                    {city_search}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={city_search ? 6 : 2} //TO-CHECK : replaces value for debug city_search ? 6 : 0
                sm={city_search ? 6 : 2}
                md={city_search ? 6 : 2}
                lg={city_search ? 6 : 2}
                xl={city_search ? 6 : 2}>
                <Grid container>
                  <Grid item className="search-container">
                    {city_search}
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            {city_search && (
              <Grid
                item
                xs={is_open ? (city_search ? 6 : 2) : city_search ? 4 : 2}
                sm={is_open ? (city_search ? 6 : 2) : city_search ? 6 : 2}
                md={is_open ? (city_search ? 5 : 2) : city_search ? 5 : 2}
                lg={is_open ? (city_search ? 6 : 2) : city_search ? 4 : 2}
                xl={is_open ? (city_search ? 6 : 2) : city_search ? 5 : 2}
                style={
                  isJsload
                    ? { marginLeft: 'auto' }
                    : {
                        marginLeft: '12rem',
                      }
                }>
                <Grid container>
                  <Grid item className="search-container">
                    {city_search}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!is_open ? (
              <Grid
                item
                xs={city_search ? 2 : 2}
                sm={city_search ? 3 : 3}
                md={city_search ? 5 : 8}
                lg={city_search ? 6 : 8}
                xl={city_search ? 5 : 8}>
                <Grid
                  justifyContent="flex-end"
                  container
                  style={
                    !isJsload && small
                      ? { position: 'absolute', top: '-30px' }
                      : { marginTop: '10px' }
                  }>
                  {is_desktop && (
                    <Grid item>
                      <div className={classes.sellProperty}>
                        <div className={classes.verticalLine}></div>
                        <div className={classes.postDetails}>
                          {/* <img
                          alt="Post Property"
                          src="/static/images/post-property.svg"
                          style={{
                            height: '23px',
                          }}></img> */}

                          <img
                            src="/static/images/home-icons.svg"
                            alt="Post Property"
                            style={{
                              objectPosition: '-261px 1px',
                              objectFit: 'cover',
                              width: '26px',
                              height: '24px',
                            }}
                          />
                          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                          <a
                            href={
                              isUserInfo(user_info)
                                ? '/post-property'
                                : `${MYACCOUNT_HOMEBAZAAR}properties/add`
                            }>
                            Post Property
                          </a>
                        </div>
                      </div>
                    </Grid>
                  )}
                  {is_desktop && <Grid item>{navbar_buttons}</Grid>}

                  <Grid
                    className={classes.sidemenuButton}
                    item
                    onClick={handleClick}
                    style={
                      // to-check
                      !isJsload && !is_desktop
                        ? {
                            position: 'relative',
                            left: '240px',
                            bottom: '40px',
                          }
                        : {}
                      // : {})
                    }>
                    {sidebar_button}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <div>
                <img src="/static/images/stickysearchicon.svg" alt="search" />
              </div>
            )}
          </Grid>
        </Toolbar>
        {all_filter_buttons && (
          <Grid
            className={classes.filterButtonsContainer}
            justifyContent="flex-start" // Add it here :)
            container>
            {all_filter_buttons}
          </Grid>
        )}
      </AppBar>

      {showBanner && renderSideMenuBar()}
    </Fragment>
  );
};

export default Navbar;
