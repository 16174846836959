import { useState } from 'react';

const useLoginForm = () => {
  const [is_login_open, setIsLoginOpen] = useState(false);
  // eslint-disable-next-line

  function openLoginModal() {
    setIsLoginOpen(true);
  }

  function closeLoginModal() {
    setIsLoginOpen(false);
  }

  return [is_login_open, openLoginModal, closeLoginModal];
};

export default useLoginForm;
