/**
 * A module containing context object, context provider and custom hook for storing/reading
 * utm parameters.
 *
 * @module Hooks/useUtmParams
 */
import React, { useState, useEffect, useContext, useMemo } from 'react';

// Empty utm parameters state
const emptyUtmParams = {
  utmSource: {
    param: 'utm_source',
    value: '',
  },
  utmMedium: {
    param: 'utm_medium',
    value: '',
  },
  utmTerm: {
    param: 'utm_term',
    value: '',
  },
  utmCampaign: {
    param: 'utm_campaign',
    value: '',
  },
  gclid: {
    param: 'gclid',
    value: '',
  },
  fbclid: {
    param: 'fbclid',
    value: '',
  },
};

// Expiry duration for utm paramters stored in local storage.
// Stored utm parameters are cleared once expiry duration is over.
const UTM_EXPIRY_DURATION = 1000 * 60 * 60 * 24 * 7; // 1 Week

const UtmContext = React.createContext();

/**
 * Compares the two utm parameter object for equality.
 * Two utm parameter objects are equal when both are truthy values,
 * have same no of keys and values at respective keys are also equal.
 *
 * @param {emptyUtmParams} utmParams1
 * @param {emptyUtmParams} utmParams2
 * @returns true if equal otherwise false
 */
function isEqual(utmParams1, utmParams2) {
  if (!utmParams1 && !utmParams2) return true;
  const keys1 = Object.keys(utmParams1);
  const keys2 = Object.keys(utmParams2);
  if (keys1.length != keys2.length) return false;
  for (const key of keys1) {
    const param1 = utmParams1[key].param;
    const param2 = utmParams2[key].param;
    const value1 = utmParams1[key].value;
    const value2 = utmParams2[key].value;
    if (param1 !== param2 || value1 !== value2) return false;
  }
  return true;
}

/**
 * Checks whether given utm parameter object has values set or not.
 * It returns false when at least one utm parameter has value set
 * in the object.
 *
 * @param {emptyUtmParams} utmParams
 * @returns true if all utm parameters are empty otherwise false
 */
function isEmpty(utmParams) {
  return Object.keys(utmParams).reduce(
    (prevFlag, key) => prevFlag && !utmParams[key].value,
    true
  );
}

/**
 * This function creates and returns deep copy of the utm parameter object.
 *
 * @param {emptyUtmParams} utmParams
 * @returns {emptyUtmParams} cloned utm parameter object
 */
function clone(utmParams) {
  return JSON.parse(JSON.stringify(utmParams));
}

/**
 * Provider component for the utm parameters.
 * It saves/reads utm parameters to/from local storage and
 * loads it into the utm parameters context object.
 *
 * @param {*} props Component props
 * @returns {React.Provider} Utm parameters context provider component
 */
export function UtmParamsProvider(props) {
  const [utmParams, setUtmParams] = useState(emptyUtmParams);

  useEffect(() => {
    const currentURL = new URL(window.location.href);
    const utmParamsNew = clone(emptyUtmParams);
    for (const key of Object.keys(utmParamsNew)) {
      const value = currentURL.searchParams.get(utmParamsNew[key].param) ?? '';
      utmParamsNew[key].value = value;
    }
    const savedUtmParams = JSON.parse(
      localStorage.getItem('hb_utm_params') ?? JSON.stringify(emptyUtmParams)
    );
    const savedUtmParamsExpiry = Number.parseInt(
      localStorage.getItem('hb_utm_params_expiry') ?? '0'
    );

    if (savedUtmParamsExpiry <= new Date().getTime()) {
      localStorage.removeItem('hb_utm_params');
      localStorage.removeItem('hb_utm_params_expiry');
    }
    if (isEqual(utmParamsNew, savedUtmParams)) {
      setUtmParams(utmParamsNew);
    } else {
      if (!isEmpty(utmParamsNew)) {
        // Persist new utm params
        localStorage.setItem('hb_utm_params', JSON.stringify(utmParamsNew));
        const expiry = new Date().getTime() + UTM_EXPIRY_DURATION;
        localStorage.setItem('hb_utm_params_expiry', expiry);
        setUtmParams(utmParamsNew);
      } else {
        setUtmParams(savedUtmParams);
      }
    }
  }, []);

  return (
    <UtmContext.Provider value={utmParams}>
      {props.children}
    </UtmContext.Provider>
  );
}

/**
 * Custom hook to obtain utm paramters from the context object.
 *
 * @returns {*} key/value pairs for utm parameters
 */
function useUtmParams() {
  const ctxValue = useContext(UtmContext);
  return useMemo(() => {
    return Object.fromEntries(
      Object.keys(ctxValue).map(key => [
        ctxValue[key].param, // parameter key
        ctxValue[key].value, // parameter value
      ])
    );
  }, [ctxValue]);
}

export default useUtmParams;
