import { useState } from 'react';
import { Button, Chip, Grid, Typography } from '@material-ui/core';
import Section from '../Section';
import HBImage from '../../../packages/hbimage';
import useStyles from './styles';
import BottomBar from '../BottomBar';

function PopularCitiesFilter(props) {
  const { rootClass, cities, defaultCitySelected, onNextClick } = props;
  const classes = useStyles();
  const [selectedCity, setSelectedCity] = useState(() => {
    return cities.find(({ id }) => id === defaultCitySelected);
  });

  return (
    <>
      <Section
        title="Popular cities"
        rootClass={rootClass}
        childRootStyle={{
          width: '100%',
        }}
        icon={
          <HBImage
            src={'/static/images/search-filter/city.svg'}
            alt="city icon"
            width={24}
            height={24}
          />
        }>
        <Grid className={classes.root} container spacing={3}>
          {cities.map(city => (
            <Grid item xs={6} key={city.id}>
              <Chip
                label={<Typography variant="body2">{city.name}</Typography>}
                className={classes.cityChip}
                variant={
                  selectedCity && selectedCity.id === city.id
                    ? 'default'
                    : 'outlined'
                }
                color={
                  selectedCity && selectedCity.id === city.id
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  setSelectedCity(city);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Section>
      <BottomBar>
        <Button
          className={classes.bottomAction}
          variant="contained"
          color="primary"
          disableElevation={true}
          onClick={() =>
            onNextClick &&
            typeof onNextClick === 'function' &&
            onNextClick(selectedCity)
          }>
          Next
        </Button>
      </BottomBar>
    </>
  );
}

export default PopularCitiesFilter;
