import Section from '../Section';
import RangeSlider from '../RangeSlider';

function CarpetAreaFilter(props) {
  const { rootClass, loading, data, defaultCarpetAreaRange, onChange } = props;
  const valueSet = new Set();
  data.minCarpetArea?.map(({ value }) => valueSet.add(value));

  const formatLabel = value => {
    return `${value} Sqft`;
  };

  return (
    <Section title="Area" rootClass={rootClass}>
      {loading && <p>Loading...</p>}
      {!loading && (
        <RangeSlider
          values={[...valueSet]}
          formatLabel={formatLabel}
          defaultRange={defaultCarpetAreaRange}
          onRangeChanged={(min, max) =>
            onChange && typeof onChange === 'function' && onChange(min, max)
          }
        />
      )}
    </Section>
  );
}

export default CarpetAreaFilter;
