import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    marginTop: '0.5rem',
  },
  cityChip: {
    minWidth: '100%',
    borderRadius: '0.3rem',
    height: 'auto',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  bottomAction: {
    margin: '0 auto',
    textTransform: 'none',
  },
}));
