import { Fragment, useState, useEffect } from 'react';
import styles from './scroll.module.css'; 

const ScrollToTopButton = ({ showBelow }) => {
  const [show, setShow] = useState(!showBelow);

  useEffect(() => {
    if (showBelow) {
      const handleScroll = () => {
        if (window.scrollY > showBelow) {
          if (!show) setShow(true);
        } else {
          if (show) setShow(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [show, showBelow]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Fragment>
      {show && (
        <img
          data-cy="arrow-top-btn"
          onClick={handleClick}
          className={styles.arrowTopButton} 
          src="/static/images/searchPageIco.svg"
          width={50}
          height={37}
          alt="arrow-top-btn"
          title="arrow-top-btn"
        />
      )}
    </Fragment>
  );
};

export default ScrollToTopButton;
