import { combineReducers } from 'redux';
import {
  livabilityReducer,
  investmentReducer,
  recommendedReducer,
  developersReducer,
  testimonialsReducer,
  headerReducer,
  footerReducer,
  loginReducer,
  menubarReducer,
  mapdetailReducer,
  countriesReducer,
  addWishlistReducer,
  BlogPostReducer,
  searchPagePropsReducer,
  getShortlistedpropertiesReducer,
  getSeenandContactedpropertiesReducer,
  recentlyPostedReducer,
  LeastPropCountReducer,
} from './home';
import {
  searchReducer,
  advancedFiltersReducer,
  discountedDealReducer,
  breadcrumReducer,
} from './search';
import { routerReducer } from 'connected-next-router';
import {
  propertyHeaderReducer,
  propertyGalleryReducer,
  propertyDetailsReducer,
  builderDetailsReducer,
  cotactedseenpropertyReducer,
} from './details';
import { virtualUrlReducer, aboutLocalityReducer } from './url';

const rootReducer = combineReducers({
  livabilityReducer: livabilityReducer,
  investmentReducer: investmentReducer,
  recommendedReducer: recommendedReducer,
  developersReducer: developersReducer,
  testimonialsReducer: testimonialsReducer,
  headerReducer: headerReducer,
  footerReducer: footerReducer,
  searchReducer: searchReducer,
  router: routerReducer,
  propertyHeaderReducer: propertyHeaderReducer,
  postcotactedseenpropertyReducer: cotactedseenpropertyReducer,
  propertyGalleryReducer: propertyGalleryReducer,
  propertyDetailsReducer: propertyDetailsReducer,
  loginReducer: loginReducer,
  menubarReducer: menubarReducer,
  mapdetailReducer: mapdetailReducer,
  countriesReducer: countriesReducer,
  advancedFiltersReducer: advancedFiltersReducer,
  discountedDealReducer: discountedDealReducer,
  virtualUrlReducer: virtualUrlReducer,
  aboutLocalityReducer: aboutLocalityReducer,
  builderDetailsReducer: builderDetailsReducer,
  breadcrumReducer: breadcrumReducer,
  addWishlistReducer: addWishlistReducer,
  getShortlistedpropertiesReducer: getShortlistedpropertiesReducer,
  getSeenandContactedpropertiesReducer: getSeenandContactedpropertiesReducer,
  BlogPostReducer: BlogPostReducer,
  searchPagePropsReducer: searchPagePropsReducer,
  recentlyPostedReducer: recentlyPostedReducer,
  LeastPropCountReducer: LeastPropCountReducer,
});

export default rootReducer;
