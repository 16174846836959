import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  flexRowSpaceBetween: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    '& > .left': {
      paddingRight: '1em',
    },
    '& > .right': {
      paddingLeft: '1em',
    },
  },
}));
