import { useState, useEffect } from 'react';
import { storeObject, retrieveObject } from '../../../config/LocalStorage';

const useUserInfo = () => {
  let [user_info, setUserInfoState] = useState('');

  useEffect(() => {
    let userInfoInLocalStorage = {};
    if (typeof window !== 'undefined') {
      userInfoInLocalStorage = retrieveObject('user');
    }
    setUserInfoState(userInfoInLocalStorage);
  }, []);

  let setUserInfo = user => {
    storeObject('user', user);
    setUserInfoState(user);
  };

  return [user_info, setUserInfo];
};

export default useUserInfo;
