import * as t from '../types';

export const propertyHeaderReducer = (
  state = {
    loading: false,
    error: null,
    property_header_details: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'propertyHeaderReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_PROPERTY_HEADER:
        return {
          ...state,
          property_header_details: action.payload,
        };
    }
  }
  return { ...state };
};

export const propertyGalleryReducer = (
  state = {
    loading: false,
    error: null,
    property_gallery_details: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'propertyGalleryReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_PROPERTY_Gallery:
        return {
          ...state,
          property_gallery_details: action.payload,
        };
    }
  }
  return { ...state };
};

export const cotactedseenpropertyReducer = (
  state = {
    loading: false,
    error: null,
    contactedseen: [],
  },
  action
) => {
  const reducer = action.meta?.reducer;

  if (reducer === 'cotactedseenpropertyReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.POST_CONTACTED_SEEN_PROPERTY:
        return {
          ...state,
          contactedseen: action.payload,
        };
    }
  }
  return { ...state };
};

export const propertyDetailsReducer = (
  state = {
    loading: false,
    error: null,
    property_details: null,
    more_projects: [],
    similar_projects: [],
    property_overview: null,
    amenity: null,
    floorplan: null,
    calculator: null,
    priceinsights: [],
    brochure: null,
    progress: null,
    bank: [],
    nearby: null,
    distance: null,
    locality: null,
    builder: null,
    faq: [],
    rating: null,
    qa: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'propertyDetailsReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_ALL_PROPERTY_DETAILS:
        return {
          ...state,
          amenity: action.payload.amenity,
          property_overview: action.payload.overview,
          floorplan: action.payload.floorplan,
          calculator: action.payload.calculator,
          priceinsights: action.payload.priceinsights,
          brochure: action.payload.brochure,
          progress: action.payload.progress,
          bank: action.payload.bank,
          nearby: action.payload.nearby,
          distance: action.payload.distance,
          locality: action.payload.locality,
          builder: action.payload.builder,
          rating: action.payload.rating,
          faq: action.payload.faq,
          qa: action.payload.qa,
          more_projects: action.payload.moreprojects,
        };
      case t.GET_PROPERTY_DETAILS:
        return {
          ...state,
          property_details: action.payload,
        };
      case t.GET_PROPERTY_AMENITY:
        return {
          ...state,
          amenity: action.payload,
        };
      case t.GET_PROPERTY_FLOORPLAN:
        return {
          ...state,
          floorplan: action.payload,
        };
      case t.GET_PROPERTY_OVERVIEW:
        return {
          ...state,
          property_overview: action.payload,
        };
      case t.GET_PROPERTY_CALCULATOR:
        return {
          ...state,
          calculator: action.payload,
        };
      case t.GET_PROPERTY_PRICE_INSIGHTS:
        return {
          ...state,
          priceinsights: action.payload,
        };
      case t.GET_PROPERTY_BROCHURE:
        return {
          ...state,
          brochure: action.payload,
        };
      case t.GET_PROPERTY_PROGRESS:
        return {
          ...state,
          progress: action.payload,
        };
      case t.GET_PROPERTY_BANK:
        return {
          ...state,
          bank: action.payload,
        };
      case t.GET_PROPERTY_NEARBY:
        return {
          ...state,
          nearby: action.payload,
        };
      case t.GET_PROPERTY_DISTANCE:
        return {
          ...state,
          distance: action.payload,
        };
      case t.GET_PROPERTY_LOCALITY:
        return {
          ...state,
          locality: action.payload,
        };
      case t.GET_PROPERTY_BUILDER:
        return {
          ...state,
          builder: action.payload,
        };
      case t.GET_PROPERTY_RATING:
        return {
          ...state,
          rating: action.payload,
        };
      case t.GET_PROPERTY_FAQ:
        return {
          ...state,
          faq: action.payload,
        };
      case t.GET_PROPERTY_QA:
        return {
          ...state,
          qa: action.payload,
        };
      case t.GET_MORE_PROJECTS:
        return {
          ...state,
          more_projects: action.payload,
        };
      case t.GET_SIMILAR_PROJECTS:
        return {
          ...state,
          similar_projects: action.payload,
        };
    }
  }
  return { ...state };
};

export const builderDetailsReducer = (
  state = {
    loading: false,
    error: null,
    builder_details: null,
  },
  action
) => {
  const reducer = action.meta?.reducer;
  if (reducer === 'builderDetailsReducer') {
    switch (action.type) {
      case t.LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case t.ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case t.GET_DEVELOPER_INFO:
        return {
          ...state,
          builder_details: action.payload,
        };
    }
  }
  return { ...state };
};
