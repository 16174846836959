import React, { useState } from 'react';

import LoginForm from '../LoginForm';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';

const LoginAndResetPassword = props => {
  const { displayView, tokenId, closeModel } = props;

  const [viewName, setViewName] = useState(displayView || 'login');
  const [token, setToken] = useState(tokenId);

  const showLogin = () => {
    setViewName('login');
  };

  const showForgetPassword = isUniqueEmail => {
    setViewName(isUniqueEmail ? 'forgetPass' : 'forgetPassOnlyMobile');
  };

  const showResetPassword = () => {
    setViewName('reset');
  };

  const closePopup = () => {
    if (closeModel) closeModel();
  };

  return (
    <>
      {viewName == 'login' && (
        <LoginForm showForgetPassword={showForgetPassword} />
      )}
      {viewName.indexOf('forgetPass') !== -1 && (
        <ForgotPassword
          showResetPassword={showResetPassword}
          forgotPassViaMob={viewName.indexOf('Mobile') !== -1}
          setToken={setToken}
          closePopup={closePopup}
        />
      )}
      {viewName == 'reset' && (
        <ResetPassword showLogin={showLogin} token={token} />
      )}
    </>
  );
};

export default LoginAndResetPassword;
