import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

function TopBar({ currentCity, onChangeClick, onClose, closeIcon }) {
  const classes = useStyles();
  const handleClose = () => {
    if (onClose) onClose();
  };
  const handleChange = () => {
    if (onChangeClick) onChangeClick();
  };
  return (
    <AppBar className={classes.root} position="sticky" color="default">
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          disableRipple={true}
          onClick={handleClose}
          aria-label="menu">
          {closeIcon ?? <CloseIcon />}
        </IconButton>
        <Typography className={classes.title} variant="body2">
          You are currently searching in{' '}
          <b style={{ textTransform: 'capitalize' }}>{currentCity ?? ''}</b>
        </Typography>
        <Button
          color="primary"
          disableRipple={true}
          className={classes.changeBtn}
          onClick={handleChange}>
          Change
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
