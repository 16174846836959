import React, { Fragment } from 'react';
import classNames from 'classnames';

const Header = props => {
  const { children, classes, style } = props;

  return (
    <Fragment>
      <section className={classNames(classes)} style={style}>
        {children}
      </section>
    </Fragment>
  );
};

export default Header;
