import { useState, useEffect } from 'react';

const useDisableBodyScroll = props => {
  const [disable_body_scroll, setDisableBodyScroll] = useState(false);

  useEffect(() => {
    const disableBodyScroll = () => {
      const body = document.body;
      if (props) {
        body.style.overflow = 'hidden';
        setDisableBodyScroll(true);
      } else {
        body.style.overflow = 'auto';
        setDisableBodyScroll(false);
      }
    };

    disableBodyScroll();
  }, [props]);

  return [disable_body_scroll];
};

export default useDisableBodyScroll;
