import { makeStyles } from '@material-ui/core';
export default makeStyles(() => ({
  root: {
    bottom: 0,
    top: 'auto',
    backgroundColor: '#F8F8FF',
    padding: '0.4rem 0',
    borderTop: '1px solid #D2DEEB',
  },
}));
