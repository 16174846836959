import { makeStyles } from '@material-ui/core';
export default makeStyles(() => ({
  root: {
    borderBottom: '1px solid #D2DEEB',
  },
  title: {
    fontSize: 12,
  },
  toolbar: {
    margin: 0,
    padding: 0,
  },
  changeBtn: {
    textTransform: 'capitalize',
    padding: 0,
    fontSize: 11,
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
  closeIcon: {
    padding: '0 0.35rem 0 1rem',
  },
}));
