/**
 * A module containing custom hook to retain previous value.
 * @module Hooks/usePreviousValue
 */
import { useEffect, useRef } from 'react';

/**
 * This custom hook saves the value passed to it and returns it in the next call i.e.
 * any present call to this hook will return the value passed in the previous call.
 *
 * @param {any} value Any value
 * @returns Value passed to the hook in the previous call.
 */
function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePreviousValue;
