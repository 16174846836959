import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import {
  InputBase,
  NativeSelect,
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useState } from 'react';

const StyledSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.08)',
    boxShadow: '0px 0px 15px -2px rgba(0,0,0,0.1);',
    marginTop: -9,
    marginLeft: -12,
    '& + .MuiSlider-thumb': {
      marginLeft: -12,
    },
    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 4px rgb(25 118 210 / 16%)',
    },
    '&$active': {
      boxShadow: '0px 0px 0px 8px rgb(25 118 210 / 16%)',
    },
  },
  active: {},
  track: {
    height: 6,
    borderRadius: 4,
  },
  mark: {
    display: 'none',
  },
  rail: {
    height: 6,
    borderRadius: 4,
  },
})(Slider);

const StyledInput = withStyles({
  input: {
    borderRadius: 4,
    fontSize: 16,
    padding: '0.4rem 2rem 0.4rem 0.6rem !important',
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: 4,
    },
    fontWeight: 'bold',
  },
})(InputBase);

const StyledSelect = withStyles({
  icon: {
    marginRight: '0.5rem',
  },
})(NativeSelect);

function RangeSlider(props) {
  const {
    values,
    formatLabel,
    onRangeChanged,
    defaultRange,
    inclusiveMin = true,
    inclusiveMax = true,
    showEmpty = false,
    emptyMinValue = 0,
    emptyMaxValue = 0,
  } = props;
  const min = values[0];
  const max = values[values.length - 1];
  const classes = useStyles();
  const [value, setValue] = useState(() => {
    let minValue = values[0];
    let maxValue = values[values.length - 1];
    if (
      Array.isArray(defaultRange) &&
      defaultRange.length === 2 &&
      Number.isFinite(defaultRange[0]) &&
      Number.isFinite(defaultRange[1]) &&
      (defaultRange[0] !== 0 || defaultRange[1] !== 0)
    ) {
      minValue = defaultRange[0];
      maxValue = defaultRange[1];
    }
    return minValue <= maxValue ? [minValue, maxValue] : [maxValue, minValue];
  });
  let getLabel = arg => arg;
  if (formatLabel && typeof formatLabel === 'function') {
    getLabel = formatLabel;
  }
  const handleValueChange = (selectedMin, selectedMax, committed) => {
    let newValue;
    let minVal = selectedMin,
      maxVal = selectedMax;

    if (selectedMax === min && !inclusiveMin) {
      maxVal = values[1];
    }
    if (selectedMin === max && !inclusiveMax) {
      minVal = values[values.length - 2];
    }
    if (minVal > maxVal) {
      newValue = [maxVal, minVal];
    } else {
      newValue = [minVal, maxVal];
    }
    setValue(newValue);
    if (committed && onRangeChanged && typeof onRangeChanged === 'function') {
      onRangeChanged(newValue[0], newValue[1]);
    }
  };

  const handleMinSelectChange = e => {
    if (e.target.value === '') {
      handleValueChange(emptyMinValue, value[1], true);
      return;
    }
    handleValueChange(Number.parseInt(e.target.value), value[1], true);
  };
  const handleMaxSelectChange = e => {
    if (e.target.value === '') {
      handleValueChange(value[0], emptyMaxValue, true);
      return;
    }
    handleValueChange(value[0], Number.parseInt(e.target.value), true);
  };

  return (
    <div>
      <div className={classes.flexRowSpaceBetween}>
        <Typography className="left" component="span">
          {getLabel(min)}
        </Typography>
        <Typography className="right" component="span">
          {getLabel(max)}
        </Typography>
      </div>
      <StyledSlider
        min={min}
        max={max}
        marks={values.map(value => ({ value }))}
        step={null}
        value={value}
        onChange={(e, [minValue, maxValue]) =>
          handleValueChange(minValue, maxValue)
        }
        onChangeCommitted={(e, [minValue, maxValue]) =>
          handleValueChange(minValue, maxValue, true)
        }
      />
      <div className={classes.flexRowSpaceBetween}>
        <StyledSelect
          value={!showEmpty ? value[0] : ''}
          input={<StyledInput />}
          IconComponent={ExpandMoreIcon}
          onChange={handleMinSelectChange}>
          {values.map((item, i) => {
            if (i === values.length - 1 && !inclusiveMax) return;
            return (
              <option key={item} value={item}>
                {getLabel(item)}
              </option>
            );
          })}
        </StyledSelect>
        <StyledSelect
          value={!showEmpty ? value[1] : defaultRange[1] ?? ''}
          input={<StyledInput />}
          IconComponent={ExpandMoreIcon}
          onChange={e =>
            handleMaxSelectChange(
              value[0],
              Number.parseInt(e.target.value),
              true
            )
          }>
          {values.map((item, i) => {
            if (i === 0 && !inclusiveMin) return;
            return (
              <option key={item} value={item}>
                {getLabel(item)}
              </option>
            );
          })}
        </StyledSelect>
      </div>
    </div>
  );
}

export default RangeSlider;
