import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import HBImage from '../../packages/hbimage';
import ClassNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Drawer from '@mui/material/Drawer';

import styled from 'styled-components';
import { MYACCOUNT_HOMEBAZAAR } from '@/config/index';
import styles from './mainMenu.module.scss';

const Mainmenu = styled('div')({
  '.MuiPaper-root': {
    background: 'rgb(0, 86, 178)',
    color: 'rgba(255, 255, 255, 0.8)',
    border: 'none',
    borderRadius: '30px 0px 0px 30px',
    boxShadow: '-10px 0px 20px #00000061',
    FontSize: '14px',
    marginRight: '-16px',
    width: '380px',
    height: '100%', // Default height
    '@media (max-width: 600px)': {
      // Adjust this value for mobile view breakpoint
      height: '100%', // Height for mobile view
    },
  },
});

const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  &:hover {
    background: rgb(0, 86, 178) !important;
    color: rgba(255, 255, 255, 0.8);
  }
`;
const LI = styled.li`
  width: 100%;
  max-width: 380px;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
    background: rgb(0, 86, 178);
  }
`;

const Item = styled.div`
  display: flex;
  padding: 8px 18px;
  padding-left: ${props => `${props.dept * 7}px`};
  align-items: center;
`;
const Label = styled.span`
  width: 100%;
  display: block;
  cursor: pointer;
  margin-left: 14px;

  color: white;
`;
const Arrow = styled.span`
  display: flex;
  height: 25px;
  width: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-top: 4px solid #fff;

    transform: ${props => (props.toggle ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

const A = styled.a`
  color: #fff;
  text-decoration: none;
`;

export default function SidebarMainMenu(props) {
  const {
    root_class,
    menuitems,
    open_control,
    sidebar_right_icons,
    handleClick,
    handleClickAway,
  } = props;

  const [activeMenus, setActiveMenus] = useState([]);

  useEffect(() => {
    return;
  }, [open_control]);

  const handleArrowClick = menuName => {
    let newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      let index = newActiveMenus.indexOf(menuName);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuName);
    }

    setActiveMenus(newActiveMenus);
  };

  function SubMenu({ dept, data, toggle }) {
    if (!toggle) {
      return null;
    }

    const deptnew = dept + 1;

    // for user info.
    const access_token = localStorage.getItem('access_token');
    return (
      <UL data-cy={'sidebar-list-container'}>
        {data.map((menu, index) => {
          const menuIndex = index;
          const menuName = `sidebar-submenu-${deptnew}-${menuIndex}-${index}`;
          const dataMenu = menu;
          const hasSubMenu =
            menu.submenu && menu.submenu.length > 0 ? true : false;
          const key = index;

          return (
            <li key={key} data-cy={'list-sub-item'}>
              {hasSubMenu ? (
                <div>
                  <Item
                    onClick={() => handleArrowClick(menuName)}
                    dept={deptnew}>
                    {dataMenu?.icon && (
                      <HBImage
                        src={dataMenu?.icon}
                        width="16px"
                        height="16px"
                        alt="banner"
                      />
                    )}
                    {/* <Label>{data.name} </Label> */}
                    <Arrow
                      onClick={() => handleArrowClick(menuName)}
                      toggle={activeMenus.includes(menuName)}
                    />
                  </Item>
                  {SubMenu({
                    dept: deptnew,
                    data: dataMenu.submenu,
                    toggle: activeMenus.includes(menuName),
                    menuIndex: menuIndex,
                  })}
                </div>
              ) : dataMenu.url.toLowerCase() == '/franchisee-model' ||
                dataMenu.url.toLowerCase() == '/support' ? (
                <></>
              ) : (
                <A
                  href={`${
                    dataMenu.name == 'Sell Your Property' && access_token
                      ? MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') +
                        'properties' +
                        '/add'
                      : // 'https://myaccount.homebazaar.com/properties/add'
                      dataMenu.url.toLowerCase() == '/franchisee-model' ||
                        dataMenu.url.toLowerCase() == '/support'
                      ? '#'
                      : dataMenu?.url
                  }`}>
                  <Item dept={deptnew}>
                    {dataMenu?.icon && (
                      <HBImage
                        src={dataMenu?.icon}
                        width="16px"
                        height="16px"
                        alt="icon"
                      />
                    )}
                    <Label>{dataMenu.name} </Label>
                  </Item>
                </A>
              )}
            </li>
          );
        })}
      </UL>
    );
  }

  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}>
        <section className={ClassNames([styles.root, root_class])}>
          {/* <CssBaseline /> */}
          <Mainmenu>
            <Drawer
              onClose={handleClickAway}
              className={ClassNames(styles.drawer, 'drawer-paper1')}
              variant="persistent"
              anchor="right"
              open={open_control}>
              <Divider />

              <div className={styles.menubarContainer}>
                <UL>
                  {menuitems.map((menu, index) => {
                    const dept = 1;
                    const menuName = `sidebar-menu-${dept}-${index}`;
                    const data = menu;
                    const hasSubMenu =
                      menu.submenu && menu.submenu.length > 0 ? true : false;
                    const menuIndex = index;

                    return (
                      <LI key={index} data-cy={'list-item'}>
                        {hasSubMenu ? (
                          <div>
                            <Item
                              onClick={() => handleArrowClick(menuName)}
                              dept={dept}>
                              {data?.icon && (
                                <>
                                  <img
                                    src={data?.icon}
                                    alt={data?.altTag}
                                    style={{
                                      width: '25px',
                                      height: '35px',
                                      objectPosition: `${
                                        index * 32 + (data?.offset ?? 0)
                                      }px`,
                                      objectFit: 'cover',
                                      position: 'relative',
                                      top: '7px',
                                    }}
                                  />
                                </>
                              )}
                              <Label>{data.name} </Label>
                              <Arrow
                                onClick={() => handleArrowClick(menuName)}
                                toggle={activeMenus.includes(menuName)}
                              />
                            </Item>
                            <>
                              <div className={styles.submenu}>
                                {SubMenu({
                                  dept: dept,
                                  data: data.submenu,
                                  toggle: activeMenus.includes(menuName),
                                  menuIndex: menuIndex,
                                })}
                              </div>
                            </>
                          </div>
                        ) : data.url.toLowerCase() == '/franchisee-model' ||
                          data.url.toLowerCase() == '/support' ? (
                          <></>
                        ) : (
                          <A
                            href={`${
                              data.url.toLowerCase() == '/franchisee-model' ||
                              data.url.toLowerCase() == '/support'
                                ? '#'
                                : data?.url
                            }`}>
                            <Item dept={dept}>
                              {data?.icon && (
                                <img
                                  src={data?.icon}
                                  alt={data?.altTag}
                                  style={{
                                    width: '25px',
                                    height: '35px',
                                    objectPosition: `${
                                      index * 32 + (data.offset ?? 0)
                                    }px`,
                                    objectFit: 'cover',
                                    position: 'relative',
                                    top: '7px',
                                  }}
                                />
                              )}
                              <Label>{data.name} </Label>
                            </Item>
                          </A>
                        )}
                      </LI>
                    );
                  })}
                </UL>
              </div>

              <section
                style={{
                  backgroundColor: '#EE6A2A',
                  display: 'block !important',
                }}
                className={styles.sidebarStickyRight}>
                {sidebar_right_icons &&
                  sidebar_right_icons.map((data, index) => {
                    const { id, icon, style, url, altTag } = data;
                    return (
                      <div key={index}>
                        {url ? (
                          <a key={index} href={url}>
                            <div
                              data-cy={'sidepanel-social-link'}
                              className={styles.sidebarRightIcon}
                              style={style}>
                              {id === 'close' ? (
                                <div id="close" onClick={handleClick}>
                                  {icon && (
                                    <img
                                      src={icon}
                                      alt={altTag}
                                      style={{
                                        width: '34px',
                                        height: '38px',
                                        objectPosition: `${
                                          index * 32 + (data.offset ?? 0)
                                        }px`,
                                        objectFit: 'cover',
                                        position: 'relative',
                                        top: '7px',
                                      }}
                                    />
                                  )}
                                </div>
                              ) : (
                                <img
                                  src={icon}
                                  alt={altTag}
                                  style={{
                                    width: '34px',
                                    height: '38px',
                                    objectPosition: `${
                                      index * 32 + (data.offset ?? 0)
                                    }px`,
                                    objectFit: 'cover',
                                    position: 'relative',
                                    top: '7px',
                                  }}
                                />
                              )}
                            </div>
                          </a>
                        ) : (
                          <div
                            data-cy={'sidepanel-close-btn'}
                            className={styles.sidebarRightIcon}
                            style={style}
                            key={index}>
                            {id === 'close' ? (
                              <div id="close" onClick={handleClick}>
                                {icon && (
                                  <img
                                    src={icon}
                                    alt={altTag}
                                    style={{
                                      width: '34px',
                                      height: '38px',
                                      objectPosition: `${
                                        index * 32 + (data.offset ?? 0)
                                      }px`,
                                      objectFit: 'cover',
                                      position: 'relative',
                                      top: '7px',
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <img
                                src={icon}
                                alt={altTag}
                                style={{
                                  width: '34px',
                                  height: '38px',
                                  objectPosition: `${
                                    index * 32 + (data.offset ?? 0)
                                  }px`,
                                  objectFit: 'cover',
                                  position: 'relative',
                                  top: '7px',
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </section>
            </Drawer>
          </Mainmenu>
        </section>
      </ClickAwayListener>
    </>
  );
}
